/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($)
{

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var NewLife = {
        // All pages
        'common': {
            init: function ()
            {
                $(document).ready(function ()
                {
                    $.ajaxSetup({ cache: true });
                    $.getScript('//connect.facebook.net/en_US/sdk.js', function ()
                    {
                        FB.init({
                            appId: APP_DATA.fb_app_id,
                            version: 'v2.7'
                        });
                    });

                    $('.share-facebook').click(function (e)
                    {
                        e.preventDefault();
                        FB.ui({
                            method: 'share',
                            display: 'popup',
                            quote: $(this).data('passage'),
                            href: APP_DATA.home_url,
                        }, function (response) { });
                    });

                    $('.share-twitter').click(function (e)
                    {
                        e.preventDefault();
                        shareThis($(this).attr('href'));
                    });

                    // $.getScript('//assets.pinterest.com/js/pinit.js');
                });

                window.twttr = (function (d, s, id)
                {
                    var js, fjs = d.getElementsByTagName(s)[0],
                        t = window.twttr || {};
                    if (d.getElementById(id))
                    {
                        return t;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "https://platform.twitter.com/widgets.js";
                    fjs.parentNode.insertBefore(js, fjs);

                    t._e = [];
                    t.ready = function (f)
                    {
                        t._e.push(f);
                    };

                    return t;
                }(document, "script", "twitter-wjs"));

                $(document).ready(function ($)
                {
                    $(window).scroll(function (event)
                    {
                        var scrollPosition = $(window).scrollTop();
                        var navigationPosition = $('#main-nav').offset();
                        var contentPosition = $('.content').offset();
                        if (scrollPosition < 100)
                        {
                            $('#main-nav').removeClass('nav-off-screen');
                        } else if (scrollPosition > 100)
                        {
                            $('#main-nav').addClass('nav-off-screen');
                        }

                        if (scrollPosition < contentPosition.top - 50)
                        {
                            $('#main-nav').removeClass('fixed-to-top');
                        } else if (scrollPosition > contentPosition.top - 50)
                        {
                            $('#main-nav').addClass('fixed-to-top');
                            $('#main-nav').removeClass('nav-off-screen');
                        }
                    });

                    // Initialize dropdowns
                    $('.dropdown-toggle').dropdown();

                    // Open dropdown menu on hover
                    $('.dropdown').hover(function ()
                    {
                        $(this).addClass('open');
                    }, function ()
                        {
                            $(this).removeClass('open');
                        });

                    // Hide main navigation when a dropdown is clicked
                    $('.nav-primary .dropdown-toggle').click(function ()
                    {
                        if ($('#main-menu').hasClass('in') && $(window).width() < 768)
                        {
                            $('#main-menu').collapse('hide');
                        }
                    });

                    // Dig Deeper book details popovers
                    $('.book-show-popover').webuiPopover({
                        placement: 'auto-top',
                        closeable: 'true',
                        animation: 'fade'
                    });

                    // Definition popovers
                    // Enable pointer events which are disbaled by default to prevent redirection on the links
                    $("a[href^='/def/']").css('pointer-events', 'all');
                    $("a[href^='/def/']").click(function (e)
                    {
                        e.preventDefault();
                        var definitionLink = $(this).attr('href');
                        var definitionLinkArray = definitionLink.split("/");
                        var definitionTerm = '"' + encodeURIComponent(definitionLinkArray[2]) + '"';
                        var el = $(this);
                        $.ajax({
                            type: "POST",
                            url: "/app/themes/newlife/lib/definition-query.php?definition_term=" + definitionTerm,
                            data: { "term": definitionTerm },
                            dataType: "html",
                            success: function (result)
                            {
                                el.webuiPopover({
                                    trigger: 'click',
                                    placement: 'auto-top',
                                    animation: 'fade',
                                    closeable: true,
                                    title: STRING.definition_popover_title,
                                    content: result
                                }).webuiPopover('show');
                            }
                        });
                    });

                    // Expand/collapse related posts group -- for articles
                    var $relatedPostsGroupHeader = $('.related-posts-group-header');
                    var $pageTitle = $('.entry-title').text();

                    $relatedPostsGroupHeader.each(function ()
                    {
                        $(this).nextUntil('.related-posts-group-header').wrapAll('<dd class="related-posts-group"><ul></ul></dd>');
                        if ($(this).next().text().match($pageTitle))
                        {
                            $(this).nextUntil('.related-posts-group-header').show();
                        } else
                        {
                            $(this).nextUntil('.related-posts-group-header').hide();
                        }
                    });

                    $relatedPostsGroupHeader.click(function ()
                    {
                        $(this).nextUntil('.related-posts-group-header').slideToggle('fast');
                        $(this).toggleClass('open');

                        $relatedPostsGroupHeader.not(this).nextUntil('.related-posts-group-header').slideUp('fast');
                        $relatedPostsGroupHeader.not(this).removeClass('open');
                    });
                });

                shareThis = function (tweetLink)
                {
                    var link = tweetLink;
                    var width = 840;
                    var height = 464;
                    var popupName = 'popup_' + width + 'x' + height;
                    var left = (screen.width - width) / 2;
                    var top = 100;
                    var params = 'width=' + width + ',height=' + height + ',location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,left=' + left + ',top=' + top;
                    window[popupName] = window.open(link, popupName, params);
                    if (window.focus)
                    {
                        window[popupName].focus();
                    }
                    return true;
                };

                // Roots Share Buttons script
                $('.entry-share-btns a').not($('.entry-share-btn-email a')).on('click', function (e)
                {
                    e.preventDefault();
                    shareThis($(this).attr('href'));
                });

                // Email share button
                $('.entry-share-btn-email a').on('click', function (e)
                {
                    e.preventDefault();
                    var subject = encodeURIComponent(document.title);
                    var body = encodeURIComponent(window.location.href);
                    window.location.href = "mailto:?subject=" + subject + "&body=" + body;
                });

                $(document).on('click', '.app-download-link', function (e)
                {

                    e.preventDefault();

                    var platform = $(this).data('platform');

                    $.getJSON('https://ipinfo.io', function (data)
                    {
                        if (data.country === APP_DATA.locale)
                        {
                            window.open(APP_DATA.app_store_links[platform].local, '_blank');
                        } else
                        {
                            window.open(APP_DATA.app_store_links[platform].international, '_blank');
                        }
                    });
                });

                $(document).ready(function ($)
                {
                    var COUNTRY;

                    $.getJSON('https://ipinfo.io', function (response)
                    {
                        COUNTRY = response.country;

                        // Do some things if the user's IP is not from the default locale
                        if (COUNTRY !== APP_DATA.locale)
                        {

                            if ($('footer li a[href="http://www.gideons.ca/"]').length > 0)
                            {
                                $('footer li a[href="http://www.gideons.ca/"]')[0].href = 'http://sharewordglobal.com/';
                            }

                            if ($('footer li a[href="http://www.gideons.ca/francais/"]').length > 0)
                            {
                                $('footer li a[href="http://www.gideons.ca/francais/"]')[0].href = 'http://sharewordglobal.com/francais/about/default';
                            }

                            if ($('footer li a[href="http://www.gideons.ca/privacy.aspx"]').length > 0)
                            {
                                $('footer li a[href="http://www.gideons.ca/privacy.aspx"]')[0].href = 'http://sharewordglobal.com/privacy';
                            }

                            if ($('footer li a[href="http://www.gideons.ca/francais/privacy.aspx"]').length > 0)
                            {
                                $('footer li a[href="http://www.gideons.ca/francais/privacy.aspx"]')[0].href = 'http://sharewordglobal.com/francais/privacy';
                            }

                            if ($('footer li a[href="http://www.gideons.ca/donate/"]').length > 0)
                            {
                                $('footer li a[href="http://www.gideons.ca/donate/"]').remove();
                            }

                            if ($('footer li a[href="http://www.gideons.ca/contact/request-scriptures"]').length > 0)
                            {
                                $('footer li a[href="http://www.gideons.ca/contact/request-scriptures"]').remove();
                            }
                        }
                    });
                });

                // Show modal dialog with message about email consent option.
                // @since 2.4.0
                $(document).ready(function ($)
                {
                    var cookieName = 'nl_2.4_email_consent_dialog_shown';
                    var expireTime = new Date(2017, 6, 31);
                    var now = new Date();
                    var loggedIn = APP_DATA.current_user_id !== "0";
                    var withinExpiryTime = Number(expireTime) > Number(now);
                    var dialogShown = Cookies.get(cookieName);

                    if (loggedIn && withinExpiryTime && !dialogShown)
                    {
                        Cookies.set(cookieName, 'true', { expires: expireTime });
                        BootstrapDialog.show({
                            title: STRING.email_consent_notification_title,
                            message: STRING.email_consent_notification_message,
                            animate: true,
                            closable: true,
                            closeByBackdrop: true,
                            closeByKeyboard: true,
                            buttons: [{
                                label: STRING.email_consent_notification_button,
                                cssClass: 'btn-md btn-default',
                                action: function (dialogRef)
                                {
                                    dialogRef.close();
                                    $(location).attr('href', APP_DATA.edit_profile_url);
                                    return false;
                                }
                            }]
                        });
                    }
                });
            },
            finalize: function ()
            {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        'articles': {
            init: function ()
            {
                // Get query variable from URL
                var pathArray = window.location.pathname.split('/');

                var category = pathArray[2];
                var topic = pathArray[3];
                var question = pathArray[4];

                if (pathArray.length >= 4 && category && topic && question)
                {
                    $.ajax({
                        type: "POST",
                        url: "/app/themes/newlife/lib/article-redirect.php?chapter=" + category + "&topic=" + topic + "&question=" + question,
                        data: { "category": category, "topic": topic, "question": question },
                        dataType: "html",
                        success: function (result)
                        {
                            window.location.replace(result);
                        }
                    });
                } else
                {
                    window.location.replace('/');
                }
            }
        },

        'home': {
            init: function ()
            {
                // Theme selector
                $(document).ready(function ($)
                {
                    $('.theme-select').click(function ()
                    {
                        // User is logged in and has an ID
                        if (APP_DATA.current_user_id !== "0")
                        {
                            var theme = $(this).val();
                            var user = APP_DATA.current_user_id;
                            $.ajax({
                                type: "POST",
                                url: APP_DATA.rest_root + 'wp/v2/users/' + user,
                                data: { "user_theme": theme },
                                beforeSend: function (xhr)
                                {
                                    xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                                },
                                success: function (result)
                                {
                                    location.reload();
                                }
                            });
                        }
                    });
                });

                // Sign up prompt for disabled theme options
                $(document).ready(function ($)
                {
                    $('.theme-select.disabled').click(function ()
                    {
                        BootstrapDialog.show({
                            title: STRING.join_modal_title,
                            message: STRING.join_modal_message,
                            animate: true,
                            closable: true,
                            closeByBackdrop: true,
                            closeByKeyboard: true,
                            buttons: [{
                                label: STRING.join_modal_button_join,
                                cssClass: 'btn-lg btn-primary',
                                action: function ()
                                {
                                    window.location.href = "/join";
                                }
                            }, {
                                label: STRING.join_modal_button_sign_in,
                                cssClass: 'btn-lg btn-default',
                                action: function ()
                                {
                                    window.location.href = "/sign-in";
                                }
                            }]
                        });
                    });
                });

                // Escape HTML characters
                // Adapted from http://stackoverflow.com/a/6020820
                function escapeHtml(string)
                {
                    return ('' + string).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
                }

                // Set default book, chapter and verse: John 1:1
                var bibleBook = 43;
                var bibleChapter = 1;
                var bibleVerse = 1;
                var searchTotal = 0;
                var searchPage = 0;

                // Set default bible book, or update from cookie
                if (typeof Cookies.get('bibleBook') === "undefined")
                {
                    Cookies.set('bibleBook', bibleBook);
                } else
                {
                    bibleBook = Cookies.get('bibleBook');
                }

                // Set default bible chapter, or update from cookie
                if (typeof Cookies.get('bibleChapter') === "undefined")
                {
                    Cookies.set('bibleChapter', bibleChapter);
                }
                else
                {
                    bibleChapter = Cookies.get('bibleChapter');
                }

                // Set default bible chapter, or update from cookie
                if (typeof Cookies.get('bibleVerse') === "undefined")
                {
                    Cookies.set('bibleVerse', bibleVerse);
                }
                else
                {
                    bibleVerse = Cookies.get('bibleVerse');
                }

                // Set default passage text size
                if (typeof Cookies.get('bibleTextSize') === "undefined")
                {
                    Cookies.set('bibleTextSize', 'medium');
                }

                // Set default red letter option
                if (typeof Cookies.get('bibleRedLetter') === "undefined")
                {
                    Cookies.set('bibleRedLetter', true);
                }

                // Update query string in URL
                updateHistory = function (bibleBook, bibleChapter)
                {
                    var state = { book: bibleBook, chapter: bibleChapter };
                    history.pushState(state, null, null);
                };

                // Verse selection
                // Initialize empty array for selected verse numbers
                var selectedVerses = [];

                // Prototypes
                Element.prototype.addClass = function (className)
                {
                    this.className = this.className + ' ' + className;
                };

                Element.prototype.removeClass = function (className)
                {
                    this.className = this.className.replace(new RegExp('(?:^|\\s)' + className + '(?!\\S)'), '').trim();
                };

                Element.prototype.hasClass = function (className)
                {
                    return ('' + this.className + '').indexOf('' + className + '') > -1;
                };

                Element.prototype.remove = function ()
                {
                    this.parentElement.removeChild(this);
                };

                Element.prototype.removeElementWhileRetainingChildren = function ()
                {
                    var fragment = document.createDocumentFragment();
                    while (this.firstChild)
                    {
                        fragment.appendChild(this.firstChild);
                    }
                    this.parentNode.replaceChild(fragment, this);
                };

                Element.prototype.insertAfter = function (newNode)
                {
                    this.parentNode.insertBefore(newNode, this.nextSibling);
                };

                Array.prototype.indexOf || (Array.prototype.indexOf = function (d, e)
                {
                    var a;
                    if (null == this)
                    {
                        throw new TypeError('"this" is null or not defined');
                    }
                    var c = Object(this);
                    var b = c.length >>> 0;
                    if (0 === b) 
                    {
                        return -1;
                    }
                    a = +e || 0;
                    if (Infinity === Math.abs(a)) 
                    {
                        a = 0;
                    }
                    if (a >= b) 
                    {
                        return -1;
                    }
                    for (a = Math.max(0 <= a ? a : b - Math.abs(a), 0); a < b;)
                    {
                        if (a in c && c[a] === d)
                        {
                            return a;
                        }
                        a++;
                    }
                    return -1;
                });

                // Constants
                var TEXT_NODE_TYPE = 3;

                // Private(ish)
                function wrapVerseById(verseId)
                {
                    var verses = document.querySelectorAll('#bible-passages span.vn');

                    var firstVerse = null;
                    var endVerse = null;
                    for (index = verseId - 1; index < verses.length; index++)
                    {
                        var verse = verses[index];

                        if (firstVerse)
                        {
                            endVerse = verse;
                            break;
                        }

                        if (verse.getAttribute('ref') === verseId)
                        {
                            firstVerse = verse;
                        }
                    }

                    var elementWrapVerseData = {
                        type: 'span',
                        className: 'verse_' + verseId,
                        style: {
                            textDecoration: 'none'
                        }
                    };

                    wrapContainersAndSubelementsWithElementData(elementWrapVerseData, firstVerse, endVerse, 'ignoreSpanWrappingElement');
                }

                function wrapContainersAndSubelementsWithElementData(elementData, startContainer, endContainer, ignoringClass)
                {
                    var tempSpan = null;
                    // Last Span in the list. Add a temp element at the end to highlight to
                    if (!endContainer)
                    {
                        tempSpan = document.createElement('span');
                        startContainer.parentElement.appendChild(tempSpan);
                        endContainer = tempSpan;
                    }

                    endContainer.addClass('ignoreSpanWrappingElement');

                    addElementDataAroundContainers(elementData, startContainer, endContainer, 'ignoreSpanWrappingElement');

                    if (tempSpan)
                    {
                        tempSpan.remove();
                    }

                    endContainer.removeClass('ignoreSpanWrappingElement');
                }

                function elementOccursBeforeEvent(closestElementsToEvent, event, element, isRTL)
                {
                    var elementRect = element.getBoundingClientRect();

                    // element's top is below the click (this means that the line you click on should have the span as the next heighest item)
                    // Need to also make sure that the left side of it is less than the X else it was the span
                    // before that was clicked and not the next one on the same line
                    if (elementRect.top < event.clientY)
                    {
                        // This span falls on the exact line of the click. Check if it is to the left or right of the click depending on language text orientation (LTR or RTL)
                        if (elementRect.top < event.clientY && elementRect.bottom > event.clientY)
                        {
                            if (isRTL)
                            {
                                if (elementRect.left > event.clientX)
                                {
                                    closestElementsToEvent.elementBefore = element;
                                }
                                // If we are on the same line as the click and the element is not in the proper position then
                                // there is no way any other element is in the proper position so we can break and return now.
                                else
                                {
                                    closestElementsToEvent.elementAfter = element;
                                }
                            } else
                            {
                                if (elementRect.left < event.clientX)
                                {
                                    closestElementsToEvent.elementBefore = element;
                                }
                                // If we are on the same line as the click and the element is not in the proper position then
                                // there is no way any other element is in the proper position so we can break and return now.
                                else
                                {
                                    closestElementsToEvent.elementAfter = element;
                                }
                            }
                        }
                        // An element along the way down that could be the nearest.
                        else
                        {
                            closestElementsToEvent.elementBefore = element;
                        }
                    }
                    else
                    {
                        closestElementsToEvent.elementAfter = element;
                    }
                }

                function findClosestElementBeforeAndAfterClickLocation(event, elementTagName, className, isRTL)
                {
                    var closestElementsToEvent = {
                        elementBefore: null,
                        elementAfter: null
                    };

                    var elements = document.querySelectorAll(elementTagName + '.' + className);

                    for (index = 0; index < elements.length; index++)
                    {
                        var element = elements[index];
                        elementOccursBeforeEvent(closestElementsToEvent, event, element, isRTL);

                        if (closestElementsToEvent.elementAfter)
                        {
                            break;
                        }
                    }

                    return closestElementsToEvent;
                }

                function wrapVerseOnClick(event)
                {
                    event = window.e || event;

                    var HTMLTag = document.getElementsByTagName('html');
                    var isRTL = HTMLTag[0].getAttribute('dir') === 'rtl';

                    var closestVerses = findClosestElementBeforeAndAfterClickLocation(event, 'span', 'vn', isRTL);

                    if (closestVerses)
                    {
                        var startContainer = closestVerses.elementBefore;
                        var endContainer = closestVerses.elementAfter;

                        var verseNumber = startContainer.getAttribute('ref');

                        if (!handleVersesAlreadyWrapped(verseNumber, true))
                        {

                            var elementWrapVerseData = {
                                type: 'span',
                                className: 'verse_' + verseNumber,
                                style: {
                                    textDecoration: 'underline'
                                }
                            };

                            wrapContainersAndSubelementsWithElementData(elementWrapVerseData, startContainer, endContainer, 'ignoreSpanWrappingElement');
                        }

                        var verses = document.querySelectorAll('#bible-passages span.verse_' + verseNumber);

                        // Verses never been underlined OR verses have just updated to be underlined then callback with adding to action list.
                        if (verses.length > 0 && (verses[0].style.textDecoration === 'undefined' || verses[0].style.textDecoration === 'underline'))
                        {
                            selectedVerses.push(verseNumber);
                        }
                        // there are no verses with that class. OR verses now have a tag of 'none'
                        else
                        {
                            var index = selectedVerses.indexOf(verseNumber);
                            if (index > -1)
                            {
                                selectedVerses.splice(index, 1);
                            }
                        }
                    }
                    else
                    {
                    }
                }

                function addElementDataAroundContainers(elementData, startContainer, endContainer, ignoreClassName)
                {

                    var containersRange = document.createRange();
                    containersRange.setStart(startContainer, 0);
                    containersRange.setEnd(endContainer, 0);

                    // check if start & end are same, if so split end node
                    if (startContainer === endContainer)
                    {
                        // splits node at startOffset, returns text after the split location
                        var splitNode = startContainer.splitText(containersRange.startOffset);
                        // modifies splitNode, returns text node after split as temp
                        var temp = splitNode.splitText(containersRange.endOffset);

                        var newNode = document.createElement(elementData.type);
                        newNode.className = elementData.className;
                        newNode.style.textDecoration = elementData.style.textDecoration;

                        // insert newNode before splitNode in the parentNode
                        var insertElement = startContainer.parentNode.insertBefore(newNode, splitNode);
                        // move splitNode into (as child of ) insertElement ( which is newNode )
                        insertElement.appendChild(splitNode);
                    } else
                    {
                        // ensure offsets are text node positions
                        var startOffset = startContainer.nodeType === TEXT_NODE_TYPE ? containersRange.startOffset : 0;
                        var endOffset = endContainer.nodeType === TEXT_NODE_TYPE ? containersRange.endOffset : 0;

                        if (startOffset > 0)
                        {
                            var firstTextNode = startContainer.splitText(startOffset);
                            containersRange.setStart(firstTextNode, 0);
                        }
                        if (endOffset > 0)
                        {
                            var endTextNode = endContainer.splitText(endOffset);
                        }

                        var textNodes = [];
                        textNodes = getTextNodes(containersRange.commonAncestorContainer, textNodes, ignoreClassName);
                        // textNodes[0].parentNode.removeChild(textNodes[0]);
                        var realNodes = [];
                        for (var i = 0; i < textNodes.length; i++)
                        {
                            if (rangeIntersectsNode(containersRange, textNodes[i]))
                            {
                                realNodes.push(textNodes[i]);
                            }
                        }
                        wrapTextNodes(elementData, realNodes, 0, realNodes.length - 1);
                    }
                }

                function wrapTextNodes(elementData, textNodes, start, end)
                {
                    var numNodes = end + 1 - start;
                    for (var i = 0; i < numNodes; i++)
                    {
                        // create node element to wrap text node
                        var newNode = document.createElement(elementData.type);
                        newNode.className = elementData.className;
                        newNode.style.textDecoration = elementData.style.textDecoration;

                        var insertElement = textNodes[i].parentNode.insertBefore(newNode, textNodes[i]);
                        // move splitNode into (as child of ) insertElement ( which is newNode )
                        insertElement.appendChild(textNodes[i]);
                    }
                }

                function rangeIntersectsNode(range, node)
                {
                    var nodeRange;
                    if (range.intersectsNode)
                    {
                        return range.intersectsNode(node);
                    } else
                    {
                        nodeRange = node.ownerDocument.createRange();
                        try
                        {
                            nodeRange.selectNode(node);
                        } catch (e)
                        {
                            nodeRange.selectNodeContents(node);
                        }
                        return range.compareBoundaryPoints(Range.END_TO_START, nodeRange) === -1 &&
                            range.compareBoundaryPoints(Range.START_TO_END, nodeRange) === 1;
                    }
                }

                // gets all text nodes inside container node and returns array nodes
                function getTextNodes(node, nodes, ignoreClassName)
                {
                    if (node.nodeType === TEXT_NODE_TYPE)
                    {
                        nodes.push(node);
                    }
                    var children = node.childNodes;
                    for (var i = 0; i < children.length; i++)
                    {
                        var child = children[i];
                        // Check if child is an ignored element.
                        if (child && (!child.hasClass || !child.hasClass(ignoreClassName)))
                        {
                            getTextNodes(child, nodes, ignoreClassName);
                        }
                    }
                    return nodes;
                }

                function handleVersesAlreadyWrapped(verseId, underline)
                {
                    var versesAlreadyWrapped = document.querySelectorAll('#bible-passages span.verse_' + verseId);

                    if (versesAlreadyWrapped)
                    {
                        for (index = 0; index < versesAlreadyWrapped.length; index++)
                        {
                            var verse = versesAlreadyWrapped[index];

                            if (underline && verse.dataset.hasHighlight === '1')
                            {
                                if (verse.style.textDecoration === 'none')
                                {
                                    verse.style.textDecoration = 'underline';
                                } else
                                {
                                    verse.style.textDecoration = 'none';
                                }
                            } else
                            {
                                verse.removeElementWhileRetainingChildren();
                            }
                        }
                    }
                    return versesAlreadyWrapped.length > 0;
                }

                // Update Bible view
                updateBibleElements = function (bibleBook, bibleChapter, bibleTemplate, bibleElement)
                {

                    if (bibleElement === '#bible-passages')
                    {
                        animateBibleUpdateOut();
                    }

                    $.ajax({
                        type: "POST",
                        url: "/app/themes/newlife/lib/" + bibleTemplate + ".php?current_book=" + bibleBook + "&current_chapter=" + bibleChapter,
                        data: { "book": bibleBook, "chapter": bibleChapter },
                        dataType: "html",
                        complete: function ()
                        {
                            // Update book and chapter cookie values
                            Cookies.set('bibleBook', bibleBook);
                            Cookies.set('bibleChapter', bibleChapter);
                            Cookies.set('bibleVerse', 1);
                        },
                        success: function (result)
                        {
                            $(bibleElement).html(result);
                            if (bibleElement === '#bible-passages')
                            {
                                // Assign current-book ID to list that matches to current view
                                $('[name="book-' + bibleBook + '"]').attr('id', 'current-book');

                                // Wrap inline notes
                                $('.tn').wrapInner('<span class="inline-note"></span>');

                                // Apply red letter option
                                if (Cookies.get('bibleRedLetter') === 'true')
                                {
                                    $('chapter .red').removeClass('red-off');
                                    $('#red-letter-control').prop('checked', true);
                                } else if (Cookies.get('bibleRedLetter') === 'false')
                                {
                                    $('chapter .red').addClass('red-off');
                                    $('#red-letter-control').prop('checked', false);
                                }

                                // Reset bible actions and verse selections
                                resetBibleActions(true, true);

                                // Apply font size option
                                if (Cookies.get('bibleTextSize') === 'small')
                                {
                                    changeBibleTextSize('small', '16px', '20px');
                                } else if (Cookies.get('bibleTextSize') === 'medium')
                                {
                                    changeBibleTextSize('medium', '18px', '24px');
                                } else if (Cookies.get('bibleTextSize') === 'large')
                                {
                                    changeBibleTextSize('large', '22px', '28px');
                                }

                                animateBibleUpdateIn();

                            }
                        }
                    });
                };

                animateBibleUpdateOut = function ()
                {
                    $('#bible-passages').addClass('loading');
                    $('#bible-content .spinner').addClass('show-spinner');
                };

                animateBibleUpdateIn = function ()
                {
                    $('#bible-content .spinner').removeClass('show-spinner');
                    $('#bible-passages').scrollTop(0).removeClass('inactive').removeClass('loading');
                };

                updateBibleView = function (bibleBook, bibleChapter, bibleVerse)
                {
                    bibleVerse = bibleVerse ? bibleVerse : 1;
                    // Update book navigation
                    updateBibleElements(bibleBook, bibleChapter, 'bible-old-testament-books', '#bible-old-testament-books');
                    updateBibleElements(bibleBook, bibleChapter, 'bible-new-testament-books', '#bible-new-testament-books');

                    // Update chapter navigation
                    updateBibleElements(bibleBook, bibleChapter, 'bible-primary-nav', '#bible-primary-nav-wrap');
                    updateBibleElements(bibleBook, bibleChapter, 'bible-secondary-nav', '#bible-secondary-nav');

                    // Update passage
                    updateBibleElements(bibleBook, bibleChapter, 'bible-passages', '#bible-passages');

                    if (APP_DATA.current_user_id !== '0')
                    {
                        $.ajax({
                            type: "GET",
                            contentType: 'application/json',
                            url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            },
                            success: function (result)
                            {
                                if (result.bible_format !== null)
                                {
                                    var verses = result.bible_format[bibleBook + '.' + bibleChapter];
                                    $.each(verses, function (key, value)
                                    {
                                        if ("highlight" in value && value.highlight !== "[DELETED]")
                                        {
                                            highlightVerse(key, value.highlight);
                                        }
                                        if ("note" in value && value.note !== "[DELETED]")
                                        {
                                            noteOnVerse(key, value.note);
                                        }
                                    });
                                }
                            },
                        });
                    }
                    setTimeout( function () {
                        document.getElementById("bible-passages").scrollTop = $("span.vn[ref='" + bibleVerse + "']")[0].offsetTop;
                    }, 500);
                };

                // Build JSON object for AJAX request update bible_format user meta
                // NOTE: Allows book, chapter and verse keys to be defined by variables
                buildBibleFormatJson = function (bookChapter, selectedVerses, type, value)
                {
                    var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                    var verseObject = {};
                    for (var index = 0; index < selectedVerses.length; index++)
                    {
                        // Force conversion to a string as it was using index with verseNumber
                        var verseNumber = "" + selectedVerses[index];
                        var jsonObj = {};
                        jsonObj[type] = value;
                        jsonObj[type + '_last_update'] = timestamp;
                        verseObject[verseNumber] = jsonObj;
                    }
                    var bookChapterObject = {};
                    bookChapterObject[bookChapter] = verseObject;
                    var bibleFormatObject = {};
                    bibleFormatObject.bible_format = bookChapterObject;
                    return JSON.stringify(bibleFormatObject);
                };

                showBibleActions = function (event)
                {
                    var $element = $('#bible-actions');
                    var $offset = $('#bible').offset();
                    var relativeX = (event.pageX - ($element.width() / 2 + $offset.left));
                    var relativeY = (event.pageY + (25 - $offset.top));
                    // Show at localtion of click event
                    $element.removeClass('hide-popover').addClass('show-popover').css({ 'top': relativeY, 'left': relativeX });
                };

                resetBibleActions = function (hideSecondaryControls, clearVerseSelection)
                {
                    // Hide bible actions popover
                    $('#bible-actions').addClass('hide-popover').removeClass('show-popover');
                    if (hideSecondaryControls === true)
                    {
                        $('#bible-actions-highlight-colours').hide();
                        $('#bible-actions-note-form').hide();
                        $('#bible-actions-share-options').hide();
                        // Reset the note input
                        $('#bible-actions-note-form form')[0].reset();
                        // Show primary controls
                        $('#bible-actions-primary-buttons').show();
                    }
                    // Reset selected verses array and text decoration
                    if (clearVerseSelection === true)
                    {
                        selectedVerses = [];
                        $('chapter p span').css('text-decoration', 'none');
                    }
                };

                showBiblePopover = function (event, name, content)
                {
                    var $element = $('#bible-popover');
                    var $offset = $('#bible').offset();
                    var relativeX = (event.pageX - ($element.width() / 2 + $offset.left));
                    var relativeY = (event.pageY + (25 - $offset.top));
                    // Show at localtion of click event
                    $element.removeClass('hide-popover').addClass('show-popover').css({ 'top': relativeY, 'left': relativeX });
                    // Add name and content to popover
                    $('#bible-popover .popover-name').html(name);
                    $('#bible-popover .popover-content').html(content);
                };

                resetBiblePopover = function ()
                {
                    // Hide bible actions popover
                    $('#bible-popover').addClass('hide-popover').removeClass('show-popover');
                };

                showJoinPrompt = function ()
                {
                    BootstrapDialog.show({
                        title: STRING.join_modal_title,
                        message: STRING.join_modal_message,
                        animate: true,
                        closable: true,
                        closeByBackdrop: true,
                        closeByKeyboard: true,
                        buttons: [{
                            label: STRING.join_modal_button_join,
                            cssClass: 'btn-lg btn-primary',
                            action: function ()
                            {
                                window.location.href = "/join";
                            }
                        }, {
                            label: STRING.join_modal_button_sign_in,
                            cssClass: 'btn-lg btn-default',
                            action: function ()
                            {
                                window.location.href = "/sign-in";
                            }
                        }]
                    });
                };

                buildBibleSidebar = function (result, openCurrent, type)
                {
                    // Inject HTML from JSON object
                    $('#bible-user-lists').html(result.bible_sidebar);
                    // Perform list wrapping and open current book list
                    wrapSidebarUserLists();
                    if (openCurrent === true)
                    {
                        openCurrentBookList();
                    }
                    if (type === 'highlight')
                    {
                        $('#bible-highlights-list .bible-sidebar-list-name').nextUntil('.bible-sidebar-list-name').slideDown(300);
                        $('#bible-highlights-list .bible-sidebar-list-name').addClass('open');
                    } else if (type === 'note')
                    {
                        $('#bible-notes-list .bible-sidebar-list-name').nextUntil('.bible-sidebar-list-name').slideDown(300);
                        $('#bible-notes-list .bible-sidebar-list-name').addClass('open');
                    }
                };

                wrapSidebarUserLists = function ()
                {
                    // Expand and collapse Bible sidebar Highlights and Notes lists
                    var $staticListHeader = $('#bible-highlights-list .bible-sidebar-list-name, #bible-notes-list .bible-sidebar-list-name');
                    $staticListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        // if ($(this).siblings().hasClass('open')) {
                        //   $(this).removeClass('open');
                        // }
                    });
                    $staticListHeader.click(function ()
                    {
                        if (APP_DATA.current_user_id !== '0')
                        {
                            // User is logged in and has unique ID number
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        } else
                        {
                            // User is not logged in (ID number is '0')
                            $(this).removeClass('open');
                            showJoinPrompt();
                        }
                    });

                    // Expand and collapse Bible sidebar book lists
                    var $bibleSidebarBookHeader = $('#bible-highlights-list .bible-sidebar-book-name, #bible-notes-list .bible-sidebar-book-name');
                    $bibleSidebarBookHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-book-name').wrapAll('<dd class="bible-sidebar-book-items"><ul class="bible-sidebar-verses"></ul></dd>');
                    });
                    $bibleSidebarBookHeader.click(function ()
                    {
                        // Toggle open state
                        $(this).nextUntil('.bible-sidebar-book-name').slideToggle('fast');
                        // Toggle open class
                        $(this).toggleClass('open');
                        // Collapse other lists
                        $bibleSidebarBookHeader.not(this).nextUntil('.bible-sidebar-book-name').slideUp('fast');
                        // Set other lists as closed
                        $bibleSidebarBookHeader.not(this).removeClass('open');
                    });

                    // Remove empty book lists
                    var emptyBibleBookList = $('.bible-sidebar-book:not(:has(.bible-sidebar-book-items))');
                    emptyBibleBookList.each(function ()
                    {
                        $(this).remove();
                    });

                    // Show message if user has deleted all highlights or hasn't added any
                    if ($('#bible-highlights-list .bible-sidebar-list-items').length > 0)
                    {
                        var emptyHighlightsList = $('#bible-highlights-list .bible-sidebar-list-items:not(:has(.bible-sidebar-book-items))');
                        emptyHighlightsList.each(function ()
                        {
                            $(this).html('<span class="bible-empty-list-message">' + STRING.bible_no_highlights_message + '</span>');
                        });
                    } else
                    {
                        if (APP_DATA.current_user_id !== "0")
                        {
                            $('#bible-highlights-list').append('<dd class="bible-sidebar-list-items"><span class="bible-empty-list-message">' + STRING.bible_no_highlights_message + '</span><dd>');
                        }
                    }

                    // Show message if user has deleted all notes or hasn't added any
                    if ($('#bible-notes-list .bible-sidebar-list-items').length > 0)
                    {
                        var emptyNotesList = $('#bible-notes-list .bible-sidebar-list-items:not(:has(.bible-sidebar-book-items))');
                        emptyNotesList.each(function ()
                        {
                            $(this).html('<span class="bible-empty-list-message">' + STRING.bible_no_notes_message + '</span>');
                        });
                    } else
                    {
                        if (APP_DATA.current_user_id !== "0")
                        {
                            $('#bible-notes-list').append('<dd class="bible-sidebar-list-items"><span class="bible-empty-list-message">' + STRING.bible_no_notes_message + '</span><dd>');
                        }
                    }

                    // Switch list contents from "display: none;" to "display: block;"
                    $('.list-wrapper').show();
                };

                wrapSidebarStaticLists = function ()
                {
                    // Expand and collapse Bible sidebar Highlights and Notes lists
                    var $whereToFindListHeader = $('#where-to-find-list .bible-sidebar-list-name');
                    $whereToFindListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        $(this).removeClass('open');
                    });
                    $whereToFindListHeader.click(function ()
                    {
                        if (APP_DATA.current_user_id !== '0')
                        {
                            // User is logged in and has unique ID number
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        } else
                        {
                            // User is not logged in (ID number is '0')
                            $(this).removeClass('open');
                            showJoinPrompt();
                        }
                    });

                    var $searchListHeader = $('#bible-search-list .bible-sidebar-list-name');
                    $searchListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        $(this).removeClass('open');
                    });
                    $searchListHeader.click(function ()
                    {
                        $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        $(this).nextUntil('.bible-sidebar-list-name').toggleClass('open');
                    });

                    var $optionsListHeader = $('#bible-options-list .bible-sidebar-list-name');
                    $optionsListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        $(this).removeClass('open');
                    });
                    $optionsListHeader.click(function ()
                    {
                        if (APP_DATA.current_user_id !== '0')
                        {
                            // User is logged in and has unique ID number
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        } else
                        {
                            // User is not logged in (ID number is '0')
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        }
                    });

                    // Expand and collapse Bible sidebar book lists
                    var $bibleSidebarBookHeader = $('#bible-options-list .bible-sidebar-book-name, #where-to-find-list .bible-sidebar-book-name');
                    $bibleSidebarBookHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-book-name').wrapAll('<dd class="bible-sidebar-book-items"><ul class="bible-sidebar-verses"></ul></dd>');
                        $(this).nextUntil('.bible-sidebar-book-name').hide();
                        $(this).removeClass('open');
                    });
                    $bibleSidebarBookHeader.click(function ()
                    {
                        // Toggle open state
                        $(this).nextUntil('.bible-sidebar-book-name').slideToggle('fast');
                        // Toggle open class
                        $(this).toggleClass('open');
                        // Collapse other lists
                        $bibleSidebarBookHeader.not(this).nextUntil('.bible-sidebar-book-name').slideUp('fast');
                        // Set other lists as closed
                        $bibleSidebarBookHeader.not(this).removeClass('open');
                    });

                    // Switch list contents from "display: none;" to "display: block;"
                    $('.list-wrapper').show();
                };

                openCurrentBookList = function ()
                {
                    // Expand and collapse Bible sidebar book lists
                    var $bibleUserListSubHeader = $('#bible-highlights-list .bible-sidebar-book-name, #bible-notes-list .bible-sidebar-book-name');
                    $bibleUserListSubHeader.each(function ()
                    {
                        // Show book list contents if it matches the current book in view
                        if ($(this).closest("#current-book").length === 1)
                        {
                            $(this).nextUntil('.bible-sidebar-book-name').slideDown('fast');
                            $(this).addClass('open');
                        } else
                        {
                            $(this).nextUntil('.bible-sidebar-book-name').hide();
                        }
                    });
                };

                changeBibleTextSize = function (size, fontSize, lineHeight)
                {
                    $('#bible-passages chapter p').css({ 'font-size': fontSize, 'line-height': lineHeight });
                    $('.bible-text-size').removeClass('on');
                    $('#bible-text-' + size).addClass('on');
                    Cookies.set('bibleTextSize', size);
                };

                scrollToBibleTop = function ()
                {
                    // Scroll to top of Bible container
                    $('html, body').animate({
                        scrollTop: $(".content").offset().top
                    }, 500);
                };

                highlightVerse = function (verseId, highlightColor)
                {
                    var verses = document.querySelectorAll('#bible-passages span.verse_' + verseId);

                    if (verses.length === 0)
                    {
                        wrapVerseById(verseId);
                        // reload verses with updated tags
                        verses = document.querySelectorAll('#bible-passages span.verse_' + verseId);
                    }

                    if (highlightColor !== null)
                    {
                        $('span.verse_' + verseId + ':gt(0)').css('background-color', 'rgba(' + highlightColor + ')');
                    } else
                    {
                        $('span.verse_' + verseId + ':gt(0)').css('background-color', 'transparent');
                    }

                    for (index = 0; index < verses.length; ++index)
                    {
                        verse = verses[index];
                        verse.dataset.hasHighlight = '1';
                    }
                };

                noteOnVerse = function (verseId, verseNote)
                {
                    var noteVerses = document.querySelectorAll('#bible-passages span.verse_' + verseId);

                    if (noteVerses.length === 0)
                    {
                        wrapVerseById(verseId);
                        // reload verses with updated tags
                        noteVerses = document.querySelectorAll('#bible-passages span.verse_' + verseId);
                    }

                    if (verseNote !== null)
                    {
                        $('span.verse_' + verseId).eq(1).prepend('<button class="note-on-verse" data-verse="' + verseId + '" value="' + escapeHtml(verseNote) + '"><span class="sr-only">' + STRING.bible_view_verse + '</span><span class="icon icon-note" aria-hidden="true"></span></button>');
                    } else
                    {
                        $('button[data-verse="' + verseId + '"]').remove();
                    }
                };

                // BIBLE VIEW
                // Set initial view
                $(document).ready(function ()
                {
                    updateBibleView(bibleBook, bibleChapter, 1);
                    wrapSidebarStaticLists();
                    wrapSidebarUserLists();
                    openCurrentBookList();
                    updateHistory(bibleBook, bibleChapter);
                });

                // Set view when user uses browser back/forward buttons
                window.addEventListener('popstate', function (e)
                {
                    updateBibleView(e.state.book, e.state.chapter, 1);
                    openCurrentBookList();
                });

                $('#bible-passages').scroll(function ()
                {
                    resetBibleActions(false, false);
                    resetBiblePopover();
                });

                // When Bible book nav link is clicked...
                $(document).on('click', '.bible-book-navigation-link', function (e)
                {
                    e.preventDefault();
                    var bibleBook = $(this).val();
                    var bibleChapter = "1";
                    $('#bible-books').slideUp(200, function ()
                    {
                        updateBibleView(bibleBook, bibleChapter, 1);
                        updateHistory(bibleBook, bibleChapter);
                    });
                });

                // When Bible chapter nav link is clicked...
                $(document).on('click', '.bible-chapter-nav-link', function (e)
                {
                    e.preventDefault();
                    var bibleBook = $(this).data("book");
                    var bibleChapter = $(this).data("chapter");
                    var bibleVerse = $(this).data("verse") ? $(this).data("verse") : 1; 
                    if ($('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideUp(200, function ()
                        {
                            updateBibleView(bibleBook, bibleChapter, bibleVerse);
                            updateHistory(bibleBook, bibleChapter);
                        });
                    } 
                    else if ($('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideUp(200, function ()
                        {
                            updateBibleView(bibleBook, bibleChapter, bibleVerse);
                            updateHistory(bibleBook, bibleChapter);
                        });
                    } 
                    else
                    {
                        updateBibleView(bibleBook, bibleChapter, bibleVerse);
                        updateHistory(bibleBook, bibleChapter);
                    }
                });

                // BIBLE NAVIGATION

                $(document).on('click', '#bible-secondary-nav .bible-chapter-nav-link', function ()
                {
                    scrollToBibleTop();
                });

                // Bible book heading is clicked
                $(document).on('click', '#bible-book-heading', function ()
                {
                    if ($('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideUp('fast', function ()
                        {
                            $('#bible-chapters-nav').removeClass('open');
                            $('#bible-chapter-heading').removeClass('open');
                        });
                    }
                    if ($('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideUp('fast', function ()
                        {
                            $('#bible-books').removeClass('open');
                            $('#bible-passages').removeClass('inactive');
                            $('#bible-book-heading').removeClass('open');
                        });
                    } else if (!$('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideDown('fast', function ()
                        {
                            $('#bible-books').addClass('open');
                            $('#bible-passages').addClass('inactive');
                            $('#bible-book-heading').addClass('open');
                        });
                    }
                });

                // Bible chapter heading is clicked
                $(document).on('click', '#bible-chapter-heading', function ()
                {
                    if ($('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideUp('fast', function ()
                        {
                            $('#bible-books').removeClass('open');
                            $('#bible-book-heading').removeClass('open');
                        });
                    }
                    if ($('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideUp('fast', function ()
                        {
                            $('#bible-chapter-heading').removeClass('open');
                            $('#bible-chapters-nav').removeClass('open');
                            $('#bible-passages').removeClass('inactive');
                        });
                    } else if (!$('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideDown('fast', function ()
                        {
                            $('#bible-chapter-heading').addClass('open');
                            $('#bible-chapters-nav').addClass('open');
                            $('#bible-passages').addClass('inactive');
                        });
                    }
                });

                // Bible book nav Old Testment header is clicked
                $(document).on('click', '.bible-old-testament-header', function ()
                {
                    $('#bible-old-testament-nav').removeClass('hidden');
                    $('#bible-old-testament-nav').slideToggle('fast');
                    $('#bible-new-testament-nav').slideUp('fast');
                    $('.bible-new-testament-header').removeClass('open');
                    $(this).toggleClass('open');
                });

                // Bible book nav New Testment header is clicked
                $(document).on('click', '.bible-new-testament-header', function ()
                {
                    $('#bible-new-testament-nav').removeClass('hidden');
                    $('#bible-new-testament-nav').slideToggle('fast');
                    $('#bible-old-testament-nav').slideUp('fast');
                    $('.bible-old-testament-header').removeClass('open');
                    $(this).toggleClass('open');
                });

                // BIBLE ACTIONS

                $(document).on('click', '#bible-passages', function ()
                {
                    if ($('#bible-passages').hasClass('inactive'))
                    {
                        $('#bible-books').slideUp('fast');
                        $('#bible-books').removeClass('open');
                        $('#bible-book-heading').removeClass('open');
                        $('#bible-chapters-nav').slideUp('fast');
                        $('#bible-chapters-nav').removeClass('open');
                        $('#bible-chapter-heading').removeClass('open');
                        $('#bible-passages').removeClass('inactive');
                    }
                });

                // Bible body text is clicked
                $(document).on('click', 'chapter p:not(.subhead, .subhead-ch)', function (event)
                {
                    if (!$('#bible-passages').hasClass('inactive'))
                    {
                        wrapVerseOnClick(event);
                        // Show Bible actions popover
                        if (selectedVerses.length > 0)
                        {
                            resetBiblePopover();
                            showBibleActions(event);
                        }
                    }
                });

                // Hide Bible actions when any other element is clicked
                $(document).mouseup(function (e)
                {
                    var container = $('#bible-actions');
                    if (!container.is(e.target) && container.has(e.target).length === 0)
                    {
                        resetBibleActions(false, false);
                    }
                });

                $(document).on('mouseover', '.note-on-verse', function (event)
                {
                    resetBibleActions(true, true);
                    showBiblePopover(event, STRING.bible_popover_title_note, $(this).val());
                });

                $(document).on('mouseout', '.note-on-verse', function ()
                {
                    resetBiblePopover();
                });

                // Hide Bible actions when any other element is clicked
                $(document).mouseup(function (e)
                {
                    var container = $('#bible-popover');
                    if (!container.is(e.target) && container.has(e.target).length === 0)
                    {
                        resetBiblePopover();
                    }
                });

                // Close button is clicked
                $('.bible-actions-close').click(function ()
                {
                    resetBibleActions(true, false);
                });

                // Highlight button is clicked
                $('#bible-actions-highlight').click(function ()
                {
                    if (APP_DATA.current_user_id !== '0')
                    {
                        // User is logged in and has unique ID number
                        $('#bible-actions-primary-buttons').hide();
                        $('#bible-actions-highlight-colours').show();
                    } else
                    {
                        // User is not logged in (ID number is '0')
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    }
                });

                // Note button is clicked
                $('#bible-actions-note').click(function ()
                {
                    if (APP_DATA.current_user_id !== '0')
                    {
                        // User is logged in and has unique ID number
                        $('#bible-actions-primary-buttons').hide();
                        $('#bible-actions-buttons-note').show();
                        $('#bible-actions-note-form').show();
                        $('#bible-actions-note-input').focus();
                    } else
                    {
                        // User is not logged in (ID number is '0')
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    }
                });

                // Share button is clicked
                $('#bible-actions-share').click(function ()
                {
                    if (APP_DATA.current_user_id !== '0')
                    {
                        // User is logged in and has unique ID number
                        $('#bible-actions-primary-buttons').hide();
                        $('#bible-actions-share-options').show();
                    } else
                    {
                        // User is not logged in (ID number is '0')
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    }
                });

                // Add highlight colour selection is clicked
                $(document).on('click', '.bible-highlight-colour', function ()
                {
                    var book = Cookies.get('bibleBook');
                    var chapter = Cookies.get('bibleChapter');
                    var bookChapter = book + '.' + chapter;
                    var type = 'highlight';
                    var value = $(this).val();
                    var verses = selectedVerses;

                    $.ajax({
                        type: "POST",
                        context: this,
                        contentType: 'application/json',
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me?filter=bible_sidebar',
                        data: buildBibleFormatJson(bookChapter, verses, type, value),
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        complete: function ()
                        {
                            $.each(verses, function (index, verse)
                            {
                                if (value === "[DELETED]")
                                {
                                    value = null;
                                }
                                highlightVerse(verse, value);
                            });
                            resetBibleActions(true, true);
                        },
                        success: function (result)
                        {
                            buildBibleSidebar(result, true, type);
                        }
                    });
                });

                // Save note button is clicked
                $('#bible-actions-save-note').click(function ()
                {
                    var book = Cookies.get('bibleBook');
                    var chapter = Cookies.get('bibleChapter');
                    var bookChapter = book + '.' + chapter;
                    var verses = selectedVerses.sort();
                    var type = 'note';
                    var value = $('#bible-actions-note-input').val();
                    $.ajax({
                        type: "POST",
                        context: this,
                        contentType: 'application/json',
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me?filter=bible_sidebar',
                        data: buildBibleFormatJson(bookChapter, [verses[0]], type, escapeHtml(value)),
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        success: function (result)
                        {
                            resetBibleActions(true, true);
                            buildBibleSidebar(result, true, type);
                            noteOnVerse(verses[0], escapeHtml(value));
                        }
                    });
                });

                $('#search_query').keyup(function (e)
                {

                    if (e.which == 13)
                        $('#bible-actions-search').click();
                });

                // Search button
                $('#bible-actions-search').click(function (e)
                {
                    selectedVerses = selectedVerses.sort();
                    var query = encodeURIComponent($("#search_query").val());
                    $.ajax({
                        type: "GET",
                        url: "/app/themes/newlife/lib/bible-sidebar-search-results.php?query=" + query,
                        dataType: "json",
                        success: function (result)
                        {
                            $("#bible-search-list ul.bible-sidebar-verses").html(result.responseText);
                            console.log(result);
                            searchTotal = result.responseText.split("</li>").length - 1;
                            $("div.totalCounts").removeClass("hideBoth");
                            $("div.totalCounts").removeClass("pageMove");
                            $("div.totalCounts").removeClass("pageEnd");
                            $("ul.bible-sidebar-verses li.pages").hide();
                            $("ul.bible-sidebar-verses li.page0").show();
                            searchPage = 0;
                            prevPage = 0;
                            
                            if (searchTotal)
                            {
                                $("div.totalCounts p").html("1-" + (searchTotal > 4 ? 5 : searchTotal) + " of " + searchTotal + " results");
                            }
                            else
                            {
                                $("div.totalCounts p").html("0 results");
                            }
                            if (searchTotal <= 5)
                            {
                                $("div.totalCounts").addClass("hideBoth");
                            }
                            $("div.totalCounts").css('display', 'flex');
                        },
                        error: function (result)
                        {
                            console.log(result);
                            if (result.status == 200)
                            {
                                $("#bible-search-list ul.bible-sidebar-verses").html(result.responseText);
                                searchTotal = result.responseText.split("</li>").length - 1;
                            }
                            $("div.totalCounts").removeClass("hideBoth");
                            $("div.totalCounts").removeClass("pageMove");
                            $("div.totalCounts").removeClass("pageEnd");
                            $("ul.bible-sidebar-verses li.pages").hide();
                            $("ul.bible-sidebar-verses li.page0").show();
                            searchPage = 0;
                            prevPage = 0;

                            if (searchTotal)
                            {
                                $("div.totalCounts p").html("1-" + (searchTotal > 4 ? 5 : searchTotal) + " of " + searchTotal + " results");
                            }
                            else
                            {
                                $("div.totalCounts p").html("0 results");
                            }
                            if (searchTotal <= 5)
                            {
                                $("div.totalCounts").addClass("hideBoth");
                            }
                            $("div.totalCounts").css('display', 'flex');
                        }
                    });
                });

                $("div.totalCounts button").click(function (e)
                {
                    var addPage = true;
                    if ($(this).hasClass('bible-nav-previous-toggle'))
                        addPage = false;

                    prevPage = searchPage;
                    searchPage += addPage ? 1 : -1;
                    $("ul.bible-sidebar-verses li.pages").hide();
                    $("ul.bible-sidebar-verses li.page" + searchPage).show();
                    if (prevPage == 0)
                    {
                        $("div.totalCounts").addClass("pageMove");
                    }
                    if (searchPage == 0)
                    {
                        $("div.totalCounts").removeClass("pageMove");
                    }
                    if ((searchPage + 1) * 5 > searchTotal)
                    {
                        $("div.totalCounts").addClass("pageEnd");
                    }
                    else if ($("div.totalCounts").hasClass('pageEnd'))
                    {
                        $("div.totalCounts").removeClass("pageEnd");
                    }
                    $("div.totalCounts p").html((searchPage * 5 + 1) + "-" + ((searchPage + 1) * 5) + " of " + searchTotal + " results");
                });

                // Share verse button is clicked
                $('.bible-share-verse').click(function (e)
                {
                    e.preventDefault();
                    var book = Cookies.get('bibleBook');
                    var chapter = Cookies.get('bibleChapter');
                    selectedVerses = selectedVerses.sort();
                    var verse = selectedVerses[0];
                    var type = $(this).val();
                    $.ajax({
                        type: "POST",
                        url: "/app/themes/newlife/lib/bible-share-verse.php?current_book=" + book + "&current_chapter=" + chapter + "&selected_verse=" + verse,
                        data: { "book": book, "chapter": chapter, "verse": verse },
                        dataType: "json",
                        success: function (result)
                        {
                            if (type === 'facebook')
                            {
                                FB.ui({
                                    method: 'share',
                                    display: 'popup',
                                    quote: result.book + ' ' + chapter + ':' + verse + ' – "' + result.passage + '"',
                                    href: APP_DATA.home_url,
                                }, function (response) { });
                            }
                            if (type === 'twitter')
                            {
                                var link = 'https://twitter.com/share?text=' + result.book + ' ' + chapter + ':' + verse + ' – "' + result.passage + '"' + '&via=GideonsCanada&url=' + APP_DATA.home_url;
                                var width = 840;
                                var height = 464;
                                var popupName = 'popup_' + width + 'x' + height;
                                var left = (screen.width - width) / 2;
                                var top = 100;
                                var params = 'width=' + width + ',height=' + height + ',location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,left=' + left + ',top=' + top;
                                window[popupName] = window.open(link, popupName, params);
                                if (window.focus)
                                {
                                    window[popupName].focus();
                                }
                                return true;
                            }
                        }
                    });
                });

                // Bible options sidebar - bookmark - view and add
                $(document).on('click', '#bible-actions-bookmark', function (event)
                {
                    if ($(this).hasClass('disabled'))
                    {
                        // User is not logged in, so prompt them to log in or join
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    } else if ($(this).hasClass('has-bookmark'))
                    {
                        // User has bookmark, so get their user object
                        $.ajax({
                            type: "GET",
                            url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            },
                            success: function (response)
                            {
                                // Render bookmarked Bible page and update history
                                var book = response.bible_bookmark.bookId;
                                var chapter = response.bible_bookmark.chapterId;
                                updateBibleView(book, chapter, 1);
                                updateHistory(book, chapter);
                            }
                        });
                    }
                    else
                    {
                        // User does not have bookmark, so bookmark current page
                        var book = Cookies.get('bibleBook');
                        var chapter = Cookies.get('bibleChapter');
                        var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                        var bookName = '';
                        $.ajax({
                            type: "POST",
                            url: "/app/themes/newlife/lib/bible-query-bookname.php?book=" + book,
                            data: { "book": book },
                            success: function (response)
                            {
                                bookName = response;
                            }
                        });
                        $.ajax({
                            type: "POST",
                            context: this,
                            url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                            data: { "bible_bookmark": { "bookId": book, "chapterId": chapter, "verseId": "1", "last_update": timestamp, "deleted": "" } },
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            },
                            success: function (response)
                            {
                                resetBibleActions(true, true);
                                $(this).html('<span>' + bookName + ' ' + chapter + '</span>').addClass('has-bookmark').attr({
                                    'title': STRING.bible_view_bookmark,
                                    'data-book': book,
                                    'data-chapter': chapter
                                });
                                $('#bible-actions-bookmark-remove').addClass('has-bookmark').attr({
                                    'data-book': book,
                                    'data-chapter': chapter
                                });
                            }
                        });
                    }
                });

                // Bible options sidebar - bookmark - remove
                $(document).on('click', '#bible-actions-bookmark-remove', function (event)
                {
                    var book = $(this).attr('data-book');
                    var chapter = $(this).attr('data-chapter');
                    var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                    $.ajax({
                        type: "POST",
                        context: this,
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                        data: { "bible_bookmark": { "bookId": book, "chapterId": chapter, "verseId": "1", "last_update": timestamp, "deleted": "[DELETED]" } },
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        success: function (response)
                        {
                            resetBibleActions(true, true);
                            $(this).removeClass('has-bookmark').attr({
                                'data-book': 'null',
                                'data-chapter': 'null'
                            });
                            $('#bible-actions-bookmark').html('<span>' + STRING.bible_bookmark + '</span>').removeClass('has-bookmark').attr({
                                'title': STRING.bible_bookmark,
                                'data-book': 'null',
                                'data-chapter': 'null'
                            });
                        }
                    });
                });

                // Verse link on note or highlight is clicked
                $(document).on('click', '.verse-link', function ()
                {
                    var bibleBookChapter = $(this).parents('li').data("book-chapter") + '';
                    bibleBookChapter = bibleBookChapter.split('.');
                    var bibleBook = bibleBookChapter[0];
                    var bibleChapter = bibleBookChapter[1];
                    var verse = $(this).parents('li').data("verse") + '';
                    if (bibleBook !== Cookies.get('bibleBook') || bibleChapter !== Cookies.get('bibleChapter'))
                    {
                        // Is a different book and chapter combination or different chapter
                        updateBibleView(bibleBook, bibleChapter, 1);
                        updateHistory(bibleBook, bibleChapter);
                        scrollToBibleTop();
                    } else if (bibleBook === Cookies.get('bibleBook') && bibleChapter === Cookies.get('bibleChapter'))
                    {
                        // Is on this view (same book and chapter combination)
                        $('#bible-passages').scrollTop(0);
                        // // TODO: Scroll to verse instead!
                        // $('#bible-passages').animate({
                        //   scrollTop: $('span.verse_' + verse).offset().top - 200
                        // }, 500);
                        scrollToBibleTop();
                    }
                });

                // Mark bible sidebar list items as deleted
                $(document).on('click', '.delete-list-item', function ()
                {
                    var bibleBook = Cookies.get('bibleBook');
                    var bibleChapter = Cookies.get('bibleChapter');
                    var itemBookChapter = $(this).parents('li').data('book-chapter') + "";
                    var itemBookChapterArray = itemBookChapter.split(".");
                    var verse = [$(this).parents('li').data('verse')];
                    var type = $(this).parents('li').data('type');
                    var value = '[DELETED]';
                    $.ajax({
                        type: "POST",
                        context: this,
                        contentType: 'application/json',
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me?filter=bible_sidebar',
                        data: buildBibleFormatJson(itemBookChapter, verse, type, value),
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        complete: function ()
                        {
                            if (type === 'highlight')
                            {
                                highlightVerse(verse, null);
                            } else if (type === 'note')
                            {
                                noteOnVerse(verse, null);
                            }
                        },
                        success: function (result)
                        {
                            // Hide deleted notes list item then rebuild sidebar
                            $(this).parents('li').slideUp(100, function ()
                            {
                                setTimeout(function ()
                                {
                                    if ($(this).parents('li:only-child').length > 0)
                                    {
                                        buildBibleSidebar(result, false);
                                    } else
                                    {
                                        buildBibleSidebar(result, true);
                                    }
                                    $('#bible-' + type + 's-list .bible-sidebar-list-name').nextUntil('.bible-sidebar-list-name').show();
                                    $('#bible-' + type + 's-list .bible-sidebar-list-name').addClass('open');
                                }, 100);
                            });
                        },
                    });
                });

                // Show inline note (.tn) text in popover
                $(document).on('mouseover', '.tn', function (event)
                {
                    if (!$('#bible-passages').hasClass('inactive'))
                    {
                        showBiblePopover(event, STRING.bible_popover_title_translation, $(this).text());
                    }
                });

                $(document).on('mouseout', '.tn', function ()
                {
                    resetBiblePopover();
                });

                // Red letter control
                $(document).on('click', '#red-letter-option', function ()
                {
                    if ($('#red-letter-control').is(':checked'))
                    {
                        $('#red-letter-control').prop('checked', false);
                    } else if ($('#red-letter-control').is(':not(:checked)'))
                    {
                        $('#red-letter-control').prop('checked', true);
                    }
                    if ($('#red-letter-control').prop('checked') === true)
                    {
                        Cookies.set('bibleRedLetter', 'true');
                        $('chapter .red').removeClass('red-off');
                    } else if ($('#red-letter-control').prop('checked') === false)
                    {
                        Cookies.set('bibleRedLetter', 'false');
                        $('chapter .red').addClass('red-off');
                    }
                });

                $('#bible-text-small').click(function (e)
                {
                    e.preventDefault();
                    changeBibleTextSize('small', '16px', '22px');
                });

                $('#bible-text-medium').click(function (e)
                {
                    e.preventDefault();
                    changeBibleTextSize('medium', '18px', '26px');
                });

                $('#bible-text-large').click(function (e)
                {
                    e.preventDefault();
                    changeBibleTextSize('large', '22px', '30px');
                });
            },
            finalize: function ()
            {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // My Commitment page scripts
        'my_commitment': {
            init: function ()
            {
                $(document).ready(function ($)
                {
                    // Show address fields if user opts to receive a Bible
                    $('#bible_opt_in').change(function ()
                    {
                        if (this.checked)
                        {
                            $('#bible-opt-in-form').fadeIn('fast');
                        } else
                        {
                            $('#bible-opt-in-form').fadeOut('fast');
                        }
                    });

                    // Submit commitment form
                    $('#commitment_form').on('submit', function (e)
                    {
                        e.preventDefault();

                        var commitmentTo = APP_DATA.commitment_emailer;
                        var commitmentSubject = '';
                        var commitmentName = $('#commitment_name').val();
                        var commitmentMessage = '';

                        if ($('#bible_opt_in').is(':checked'))
                        {
                            commitmentSubject = 'My Commitment & Bible Request';
                            var commitmentAddressee = '';
                            $('.commitment-bible-addressee').each(function ()
                            {
                                commitmentAddressee += $(this).val() + '\n';
                            });
                            commitmentMessage = commitmentName + '\n' + commitmentAddressee;
                        } else
                        {
                            commitmentSubject = 'My Commitment';
                            commitmentMessage = commitmentName;
                        }

                        var commitmentData = {
                            to: APP_DATA.commitment_emailer,
                            subject: commitmentSubject,
                            body: commitmentMessage
                        };

                        // Send commitment email
                        // TODO: POST to live e-mail handler
                        if (commitmentMessage)
                        {
                            $.ajax({
                                method: 'POST',
                                url: APP_DATA.emailer_script,
                                data: JSON.stringify(commitmentData),
                                contentType: 'application/json',
                                success: function (response)
                                {
                                    var responseSuccessTitle = STRING.commitment_success_title;
                                    var responseSuccessMessage = STRING.commitment_success_message;

                                    $('#commitment_form')[0].reset();

                                    BootstrapDialog.show({
                                        title: responseSuccessTitle,
                                        message: responseSuccessMessage,
                                        animate: true,
                                        closable: true,
                                        closeByBackdrop: true,
                                        closeByKeyboard: true,
                                        buttons: [{
                                            label: STRING.modal_button_dismiss,
                                            cssClass: 'btn-md btn-default',
                                            action: function (dialogRef)
                                            {
                                                dialogRef.close();
                                            }
                                        }]
                                    });
                                },
                                fail: function (response)
                                {
                                    var responseFailureTitle = STRING.commitment_failure_title;
                                    var responseFailureMessage = STRING.commitment_failure_message;

                                    BootstrapDialog.show({
                                        title: responseFailureTitle,
                                        message: responseFailureMessage,
                                        animate: true,
                                        closable: true,
                                        closeByBackdrop: true,
                                        closeByKeyboard: true,
                                        buttons: [{
                                            label: STRING.modal_button_retry,
                                            cssClass: 'btn-md btn-default',
                                            action: function (dialogRef)
                                            {
                                                dialogRef.close();
                                            }
                                        }]
                                    });
                                }
                            });
                        }
                    });
                });
            }
        },

        'request_a_prayer': {
            init: function ()
            {
                $(document).ready(function ($)
                {
                    // Request a Prayer AJAX submission
                    $('#prayer_request_form').on('submit', function (e)
                    {
                        e.preventDefault();

                        var prayerBrief = $('#prayer_brief').val();
                        var prayerBackground = $('#prayer_background').val();
                        var prayerTitleString = prayerBrief;
                        var prayerTitle;

                        if (prayerTitleString.length > 75)
                        {
                            prayerTitle = $.trim(prayerTitleString).substring(0, 75).split(" ").slice(0, -1).join(" ") + "...";
                        } else
                        {
                            prayerTitle = prayerTitleString;
                        }

                        var prayerTagsIDs = [];
                        $('[name="prayer_tags[]"]').each(function ()
                        {
                            if (this.checked)
                            {
                                prayerTagsIDs.push(this.value);
                            }
                        });

                        var prayerTags = [];
                        $('[name="prayer_tags[]"]').each(function ()
                        {
                            if (this.checked)
                            {
                                prayerTags.push(this.dataset.prayerTag);
                            }
                        });

                        var prayerStatus = "";
                        $('[name="prayer_status"]').each(function ()
                        {
                            if (this.checked)
                            {
                                prayerStatus = "publish";
                            } else
                            {
                                prayerStatus = "private";
                            }
                        });

                        var prayerData = {
                            title: prayerTitle,
                            excerpt: prayerBrief,
                            content: prayerBackground,
                            status: prayerStatus,
                            prayer_tags: prayerTagsIDs,
                            type: "prayer"
                        };

                        if (prayerBrief)
                        {
                            $.ajax({
                                method: "POST",
                                url: APP_DATA.rest_root + 'wp/v2/prayers',
                                data: prayerData,
                                beforeSend: function (xhr)
                                {
                                    xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                                },
                                success: function (response)
                                {
                                    var responseSuccessTitle = STRING.prayer_success_title;
                                    var responseSuccessMessage = STRING.prayer_success_message;
                                    var siteURL = APP_DATA.site_url;

                                    $('#prayer_request_form')[0].reset();

                                    BootstrapDialog.show({
                                        title: responseSuccessTitle,
                                        message: responseSuccessMessage,
                                        animate: true,
                                        closable: true,
                                        closeByBackdrop: true,
                                        closeByKeyboard: true,
                                        buttons: [{
                                            label: STRING.modal_button_dismiss,
                                            cssClass: 'btn-md btn-default',
                                            action: function (dialogRef)
                                            {
                                                dialogRef.close();
                                            }
                                        }]
                                    });

                                    var prayerRequestData = {
                                        to: APP_DATA.prayer_emailer,
                                        subject: "Prayer Request from NewLife",
                                        body: "Prayer:" + '\n' + prayerBrief + '\n\n' + "Topic(s):" + '\n' + prayerTags.join(', ') + '\n\n' + "Background:" + '\n' + prayerBackground + '\n\n' + "Status:" + '\n' + prayerStatus + '\n\n' + "View on NewLife:" + '\n' + siteURL + "/wp-admin/post.php?post=" + response.id + "&action=edit"
                                    };

                                    $.ajax({
                                        method: "POST",
                                        url: APP_DATA.emailer_script,
                                        data: JSON.stringify(prayerRequestData),
                                        contentType: "application/json"
                                    });

                                    // Set cookie used to show notfication in profile for 10 days unless dismissed
                                    Cookies.set("prayerRequest", true, { expires: 10 }, { path: '/profile' });
                                },
                            });
                        } else
                        {
                            var responseFailureTitle = STRING.prayer_failure_title;
                            var responseFailureMessage = STRING.prayer_failure_message;

                            BootstrapDialog.show({
                                title: responseFailureTitle,
                                message: responseFailureMessage,
                                animate: true,
                                closable: true,
                                closeByBackdrop: true,
                                closeByKeyboard: true,
                                buttons: [{
                                    label: STRING.modal_button_retry,
                                    cssClass: 'btn-md btn-default',
                                    action: function (dialogRef)
                                    {
                                        dialogRef.close();
                                    }
                                }]
                            });
                        }
                    });
                });
            }
        },

        'register_your_church': {
            init: function ()
            {
                // Register Your Church AJAX submission
                $(document).ready(function ($)
                {
                    $.getJSON('https://ipinfo.io', function (response)
                    {
                        if (response.country !== "CA")
                        {
                            $('.church_terms_of_regitration_label').text(STRING.church_registration_terms);
                        }
                    });

                    $('#register_church_form').on('submit', function (e)
                    {
                        e.preventDefault();

                        var churchDialog = {};

                        // Disabled the button while submitting.
                        $('#register_church_form').find('button[type="submit"]').attr('disabled', true);

                        var fullAddress = function ()
                        {
                            street = $('#church_street').val().split(' ').join('+');
                            city = $('#church_city').val().split(' ').join('+');
                            state = $('#church_province').val().split(' ').join('+');
                            return street + ',+' + city + ',+' + state;
                        }();

                        var createChurch = function (lat, lng)
                        {
                            // Gather Church post data
                            // TODO: Add reverse geocoding API call to automatically set latitude and logitude
                            var church = {
                                type: "wpsl_stores",
                                status: "pending",
                                title: $('#church_name').val(),
                                wpsl_denomination: $('#church_denomination').val(),
                                wpsl_address: $('#church_street').val(),
                                wpsl_city: $('#church_city').val(),
                                wpsl_state: $('#church_province').val(),
                                wpsl_country: $('#church_country').val(),
                                wpsl_zip: $('#church_postal_code').val(),
                                wpsl_lat: lat,
                                wpsl_lng: lng,
                                wpsl_phone: $('#church_phone').val(),
                                wpsl_url: $('#church_website').val(),
                                wpsl_email: $('#church_email').val(),
                                wpsl_contact_name: $('#church_contact').val(),
                                wpsl_service_times: $('#church_service_times').val(),
                                wpsl_pastor: $('#church_pastor').val(),
                                wpsl_office_hours: $('#church_office_hours').val(),
                                wpsl_specialized_ministries: $('#church_specialized_ministry').val(),
                                wpsl_children: $("input[name='church_childrens_ministry']").is(":checked") ? 'Yes' : 'No'
                            };

                            $.ajax({
                                method: "POST",
                                url: APP_DATA.rest_root + 'wp/v2/churches',
                                data: church,
                                beforeSend: function (xhr)
                                {
                                    xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                                },
                                success: function (response)
                                {
                                    var responseSuccessTitle = STRING.church_registration_success_title;
                                    var responseSuccessMessage = STRING.church_registration_success_message;
                                    // Reset form values
                                    $('#register_church_form')[0].reset();
                                    // Enble the button after submitting.
                                    $('#register_church_form').find('button[type="submit"]').attr('disabled', false);
                                    // Launch modal dialog to display success message
                                    BootstrapDialog.show({
                                        title: responseSuccessTitle,
                                        message: responseSuccessMessage,
                                        animate: true,
                                        closable: true,
                                        closeByBackdrop: true,
                                        closeByKeyboard: true,
                                        buttons: [{
                                            label: STRING.modal_button_dismiss,
                                            cssClass: 'btn-md btn-default',
                                            action: function (dialogRef)
                                            {
                                                dialogRef.close();
                                            }
                                        }]
                                    });

                                    // Send email to Gideons
                                    // TODO: POST to live e-mail handler and use distinct send to address
                                    var registerChurchSubject = 'Church Registration: ' + church.title;
                                    var registerChurchMessage = church.title + '\n' + church.wpsl_address + '\n' + church.wpsl_city + '\n' + church.wpsl_state + '\n' + church.wpsl_zip + '\n' + church.wpsl_phone + '\n' + church.wpsl_url + '\n' + church.wpsl_email + '\n\nContact: ' + church.wpsl_contact_name + '\nPastor: ' + church.wpsl_pastor + '\nDenomination: ' + church.wpsl_denomination + '\nSpecialized Ministry: ' + church.wpsl_specialized_ministries + '\nChildrens\' Minsitry: ' + church.wpsl_children + '\nOffice Hours: ' + church.wpsl_office_hours;

                                    var registerChurchData = {
                                        to: APP_DATA.church_emailer,
                                        subject: registerChurchSubject,
                                        body: registerChurchMessage
                                    };

                                    $.ajax({
                                        method: 'POST',
                                        url: APP_DATA.emailer_script,
                                        data: JSON.stringify(registerChurchData),
                                        contentType: 'application/json'
                                    });
                                },
                                error: function ()
                                {
                                    var responseFailureTitle = STRING.church_registration_failure_title;
                                    var responseFailureMessage = STRING.church_registration_failure_message;
                                    // Enble the button after submitting.
                                    $('#register_church_form').find('button[type="submit"]').attr('disabled', false);
                                    // Launch modal dialog to display error message
                                    BootstrapDialog.show({
                                        title: responseFailureTitle,
                                        message: responseFailureMessage,
                                        animate: true,
                                        closable: true,
                                        closeByBackdrop: true,
                                        closeByKeyboard: true,
                                        buttons: [{
                                            label: STRING.modal_button_retry,
                                            cssClass: 'btn-md btn-default',
                                            action: function (dialogRef)
                                            {
                                                dialogRef.close();
                                            }
                                        }]
                                    });
                                }
                            });
                        };

                        $.ajax({
                            method: "GET",
                            url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + fullAddress + '&key=' + APP_DATA.googlemaps_api_key,
                            success: function (response)
                            {
                                var churchModalText = $('<p>').text(STRING.church_registration_modal_text)

                                var resultsList = $('<ul>')
                                    .attr('id', 'church-results-list');

                                response.results.forEach(function (result)
                                {
                                    var lat = result.geometry.location.lat;
                                    var lng = result.geometry.location.lng;
                                    var button = $('<button>')
                                        .attr('class', 'church-result')
                                        .attr('value', lat + ',' + lng)
                                        .text(result.formatted_address)
                                        .on('click', function (event)
                                        {
                                            event.preventDefault();
                                            $(this).attr('disabled', true);
                                            createChurch(lat, lng);
                                            churchDialog.close();
                                        })
                                    var item = $('<li>').append(button);
                                    resultsList.append(item);
                                });

                                var churchModalContent = $('<div>')
                                    .append(churchModalText)
                                    .append(resultsList);

                                churchDialog = BootstrapDialog.show({
                                    title: STRING.church_registration_modal_title,
                                    message: churchModalContent,
                                    animate: true,
                                    closable: true,
                                    closeByBackdrop: true,
                                    closeByKeyboard: true,
                                    cssClass: 'church-list-confirm-dialog',
                                    buttons: [{
                                        label: STRING.church_registration_modal_refine_address,
                                        cssClass: 'btn-md btn-default',
                                        action: function (dialogRef)
                                        {
                                            dialogRef.close();
                                            // Enble the button after submitting.
                                            $('#register_church_form').find('button[type="submit"]').attr('disabled', false);
                                        }
                                    }]
                                });
                            },
                            error: function ()
                            {
                                console.log('Error fetching latitude and longitude.')
                            }
                        });

                        // $(document).on('click', '.church-result', function(event) {
                        //     $(this).attr('disabled', true);
                        //     var values = event.target.value.split(',');
                        //     var lat = values[0];
                        //     var lng = values[1];
                        //     createChurch(lat, lng);
                        //     churchDialog.close();
                        // })
                    });
                });
            }
        },
        'join': {
            init: function ()
            {
                $(document).ready(function ($)
                {
                    $('input[name="is_of_faith"]').click(function ()
                    {
                        if ($(this).attr("value") === "no")
                        {
                            $(".user-which-faith").fadeOut(200, function ()
                            {
                                $("#which_faith").val("undefined");
                            });
                            $(".user-faith-engagement").fadeOut(200, function ()
                            {
                                $("input[name='faith_engagement']").each(function ()
                                {
                                    if ($(this).attr("value") === "0")
                                    {
                                        $(this).prop("checked", true);
                                    } else
                                    {
                                        $(this).prop("checked", false);
                                    }
                                });
                            });
                            // Set a cookies to enabled notification on profile promting user to complete their profile
                            Cookies.set('incompleteProfile', true);
                        } else
                        {
                            $(".user-which-faith").fadeIn(200);
                            $(".user-faith-engagement").fadeIn(200);
                            // Remove incomplete profile cookie, don't show notification
                            Cookies.remove('incompleteProfile');
                        }
                    });
                });
            }
        },
        'profile': {
            init: function ()
            {
                $(document).ready(function ($)
                {
                    // Theme selector
                    $('.theme-select').click(function ()
                    {
                        var theme = $(this).val();
                        var user = APP_DATA.current_user_id;
                        $.ajax({
                            type: "POST",
                            url: APP_DATA.rest_root + 'wp/v2/users/' + user,
                            data: { "user_theme": theme },
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            },
                            success: function (result)
                            {
                                location.reload();
                            }
                        });
                    });

                    // Daily inspirations selector
                    $('.inspiration-select').click(function ()
                    {
                        var inspiration = $(this).val();
                        var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                        $.ajax({
                            type: "POST",
                            url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                            data: { "user_inspiration": { "group": inspiration, "last_update": timestamp } },
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            }
                        });
                        $('.inspiration-select').not(this).removeClass('active-inspiration').addClass('inactive-inspiration');
                        $(this).addClass('active-inspiration').removeClass('inactive-inspiration');
                    });

                    // Remove cookies that tell notifications to be shown
                    $('.alert-dismissible button.close').click(function ()
                    {
                        notificationType = $(this).parents().data('notification-type');
                        Cookies.remove(notificationType);
                    });
                });

                // Verse link on note or highlight is clicked
                $(document).on('click', '.verse-link', function ()
                {
                    var bibleBookChapter = $(this).parents('li').data('book-chapter') + '';
                    bibleBookChapter = bibleBookChapter.split('.');
                    var bibleBook = bibleBookChapter[0];
                    var bibleChapter = bibleBookChapter[1];
                    var bibleVerse = $(this).parents('li').data('verse') + '';

                    var bibleUrl = APP_DATA.bible_page_link + bibleBook + '/' + bibleChapter + '/' + bibleVerse;

                    // console.log(bibleUrl);

                    window.location.href = bibleUrl;
                });

                // Build JSON object for AJAX request update bible_format user meta
                // NOTE: Allows book, chapter and verse keys to be defined by variables
                buildBibleFormatJson = function (bookChapter, selectedVerses, type, value)
                {
                    var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                    var verseObject = {};
                    for (var index = 0; index < selectedVerses.length; index++)
                    {
                        // Force conversion to a string as it was using index with verseNumber
                        var verseNumber = "" + selectedVerses[index];
                        var jsonObj = {};
                        jsonObj[type] = value;
                        jsonObj[type + '_last_update'] = timestamp;
                        verseObject[verseNumber] = jsonObj;
                    }
                    var bookChapterObject = {};
                    bookChapterObject[bookChapter] = verseObject;
                    var bibleFormatObject = {};
                    bibleFormatObject.bible_format = bookChapterObject;
                    return JSON.stringify(bibleFormatObject);
                };

                // Mark bible sidebar list items as deleted
                $(document).on('click', '.delete-list-item', function ()
                {
                    var bibleBook = Cookies.get('bibleBook');
                    var bibleChapter = Cookies.get('bibleChapter');
                    var itemBookChapter = $(this).parents('li').data('book-chapter') + "";
                    var itemBookChapterArray = itemBookChapter.split(".");
                    var verse = [$(this).parents('li').data('verse')];
                    var type = $(this).parents('li').data('type');
                    var value = '[DELETED]';
                    $.ajax({
                        type: "POST",
                        context: this,
                        contentType: 'application/json',
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me?filter=bible_sidebar',
                        data: buildBibleFormatJson(itemBookChapter, verse, type, value),
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        success: function (result)
                        {
                            // Hide deleted notes list item then rebuild sidebar
                            // TODO: Hide book list if it has now items
                            $(this).parents('li').slideUp(100, function ()
                            {
                                setTimeout(function ()
                                {
                                    $('#bible-' + type + 's-list .bible-sidebar-list-name').nextUntil('.bible-sidebar-list-name').show();
                                    $('#bible-' + type + 's-list .bible-sidebar-list-name').addClass('open');
                                }, 100);
                            });
                        },
                    });
                });

                wrapSidebarUserLists = function ()
                {
                    // Expand and collapse Bible sidebar Highlights and Notes lists
                    var $staticListHeader = $('#bible-highlights-list .bible-sidebar-list-name, #bible-notes-list .bible-sidebar-list-name');
                    $staticListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        $(this).removeClass('open');
                    });
                    $staticListHeader.click(function ()
                    {
                        if (APP_DATA.current_user_id !== '0')
                        {
                            // User is logged in and has unique ID number
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        } else
                        {
                            // User is not logged in (ID number is '0')
                            $(this).removeClass('open');
                            showJoinPrompt();
                        }
                    });

                    // Expand and collapse Bible sidebar book lists
                    var $bibleSidebarBookHeader = $('#bible-highlights-list .bible-sidebar-book-name, #bible-notes-list .bible-sidebar-book-name');
                    $bibleSidebarBookHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-book-name').wrapAll('<dd class="bible-sidebar-book-items"><ul class="bible-sidebar-verses"></ul></dd>');
                    });
                    $bibleSidebarBookHeader.click(function ()
                    {
                        // Toggle open state
                        $(this).nextUntil('.bible-sidebar-book-name').slideToggle('fast');
                        // Toggle open class
                        $(this).toggleClass('open');
                        // Collapse other lists
                        $bibleSidebarBookHeader.not(this).nextUntil('.bible-sidebar-book-name').slideUp('fast');
                        // Set other lists as closed
                        $bibleSidebarBookHeader.not(this).removeClass('open');
                    });

                    // Remove empty book lists
                    var emptyBibleBookList = $('.bible-sidebar-book:not(:has(.bible-sidebar-book-items))');
                    emptyBibleBookList.each(function ()
                    {
                        $(this).remove();
                    });

                    // Show message if user has deleted all highlights or hasn't added any
                    var emptyHighlightsList = $('#bible-highlights-list .bible-sidebar-list-items:not(:has(.bible-sidebar-book-items))');
                    emptyHighlightsList.each(function ()
                    {
                        $(this).html('<span class="bible-empty-list-message">' + STRING.bible_no_highlights_message + '</span>');
                    });

                    // Show message if user has deleted all notes or hasn't added any
                    var emptyNotesList = $('#bible-notes-list .bible-sidebar-list-items:not(:has(.bible-sidebar-book-items))');
                    emptyNotesList.each(function ()
                    {
                        $(this).html('<span class="bible-empty-list-message">' + STRING.bible_no_notes_message + '</span>');
                    });
                };

                openFirstBookList = function ()
                {
                    // // TODO: Rework this snippet to act only on first book list
                    // // Expand and collapse Bible sidebar book lists
                    // var $bibleUserListSubHeader = $('#bible-highlights-list .bible-sidebar-book-name, #bible-notes-list .bible-sidebar-book-name');
                    // $bibleUserListSubHeader.each(function() {
                    //   // Show book list contents if it matches the current book in view
                    //   if ($(this)) {
                    //     $(this).nextUntil('.bible-sidebar-book-name').show();
                    //     $(this).addClass('open');
                    //   } else {
                    //     $($bibleUserListSubHeader).nextUntil('.bible-sidebar-book-name').hide();
                    //   }
                    // });
                    // // Switch list contents from "display: none;" to "display: block;"
                    $('.list-wrapper').show();
                };

                wrapSidebarUserLists();
                openFirstBookList();

                function deleteChurch(id, listItem)
                {
                    $.ajax({
                        type: 'DELETE',
                        url: APP_DATA.rest_root + 'wp/v2/churches/' + id,
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        success: function (response)
                        {
                            // listItem.removeClass('hide-animate-before').addClass('hide-animate-after');
                            listItem.slideUp('fast');
                        },
                        error: function (response)
                        {
                            console.log('[DELETE CHURCH] Error deleting ID: ' + id);
                        }
                    });
                }

                $(document).on('click', '.profile_delete_church', function (e)
                {
                    e.preventDefault();
                    var listItem = $(this).closest('li');
                    var id = $(this).data('id');
                    var name = $(this).data('name');
                    var responseSuccessTitle = STRING.church_delete_confirm_title;
                    var responseSuccessMessage = STRING.church_delete_confirm_message;
                    BootstrapDialog.show({
                        title: responseSuccessTitle,
                        message: responseSuccessMessage,
                        animate: true,
                        closable: true,
                        closeByBackdrop: true,
                        closeByKeyboard: true,
                        buttons: [{
                            label: STRING.modal_button_delete,
                            cssClass: 'btn-md btn-default',
                            action: function (dialogRef)
                            {
                                dialogRef.close();
                                deleteChurch(id, listItem);
                            }
                        },
                        {
                            label: STRING.modal_button_cancel,
                            cssClass: 'btn-md btn-default',
                            action: function (dialogRef)
                            {
                                dialogRef.close();
                            }
                        }]
                    });
                });

                $(document).on('click', '#my_churches_load_more', function (e)
                {
                    e.preventDefault();
                    var page = $(this).data('page') + 1;
                    var perPage = $('#my_churches_per_page').val();
                    var index = $(this).data('index');
                    $.ajax({
                        type: 'POST',
                        url: APP_DATA.ajax_url,
                        data: {
                            action: 'newlife_my_churches_pagation',
                            my_churches_per_page: perPage,
                            my_churches_page: page,
                            my_churches_index: index
                        },
                        context: this,
                        beforeSend: function (xhr)
                        {
                            $(this).attr("disabled", "disabled");
                        },
                        success: function (response)
                        {
                            $('#my_churches').append(response);
                            $(this).closest('li').remove()
                        },
                        error: function (response)
                        {
                        }
                    });
                });
            }
        },

        'bible': {
            init: function ()
            {
                // Escape HTML characters
                // Adapted from http://stackoverflow.com/a/6020820
                function escapeHtml(string)
                {
                    return ('' + string).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
                }

                var pathArray = window.location.pathname.split('/');
                var pathBase = pathArray[1];

                // Set default book, chapter and verse: John 1:1
                var bibleBook = 43;
                var bibleChapter = 1;
                var bibleVerse = 1;
                var searchTotal = 0;
                var searchPage = 0;

                // Set default bible book, or update from cookie
                if (typeof Cookies.get('bibleBook') === "undefined")
                {
                    Cookies.set('bibleBook', bibleBook);
                }
                else
                {
                    bibleBook = Cookies.get('bibleBook');
                }

                // Set default bible chapter, or update from cookie
                if (typeof Cookies.get('bibleChapter') === "undefined")
                {
                    Cookies.set('bibleChapter', bibleChapter);
                }
                else
                {
                    bibleChapter = Cookies.get('bibleChapter');
                }

                // Set default bible chapter, or update from cookie
                if (typeof Cookies.get('bibleVerse') === "undefined")
                {
                    Cookies.set('bibleVerse', bibleVerse);
                }
                else
                {
                    bibleVerse = Cookies.get('bibleVerse');
                }

                // Set default bible chapter, or update from URL
                if (typeof pathArray[2] !== "undefined" && pathArray.length >= 4)
                {
                    bibleBook = pathArray[2];
                    Cookies.set('bibleBook', pathArray[2]);
                }

                if (typeof pathArray[3] !== "undefined" && pathArray.length >= 4)
                {
                    bibleChapter = pathArray[3];
                    Cookies.set('bibleChapter', pathArray[3]);
                }

                if (typeof pathArray[4] !== "undefined" && pathArray.length >= 4)
                {
                    bibleVerse = pathArray[4];
                    Cookies.set('bibleVerse', pathArray[4]);
                }

                // Set default passage text size
                if (typeof Cookies.get('bibleTextSize') === "undefined")
                {
                    Cookies.set('bibleTextSize', 'medium');
                }

                // Set default red letter option
                if (typeof Cookies.get('bibleRedLetter') === "undefined")
                {
                    Cookies.set('bibleRedLetter', true);
                }

                // Update query string in URL
                updateHistory = function (bibleBook, bibleChapter)
                {
                    var state = { book: bibleBook, chapter: bibleChapter };
                    var newUrl = '/' + pathBase + '/' + bibleBook + '/' + bibleChapter + '/' + bibleVerse;
                    history.pushState(state, null, newUrl);
                };

                // Verse selection
                // Initialize empty array for selected verse numbers
                var selectedVerses = [];

                // Prototypes
                Element.prototype.addClass = function (className)
                {
                    this.className = this.className + ' ' + className;
                };

                Element.prototype.removeClass = function (className)
                {
                    this.className = this.className.replace(new RegExp('(?:^|\\s)' + className + '(?!\\S)'), '').trim();
                };

                Element.prototype.hasClass = function (className)
                {
                    return ('' + this.className + '').indexOf('' + className + '') > -1;
                };

                Element.prototype.remove = function ()
                {
                    this.parentElement.removeChild(this);
                };

                Element.prototype.removeElementWhileRetainingChildren = function ()
                {
                    var fragment = document.createDocumentFragment();
                    while (this.firstChild)
                    {
                        fragment.appendChild(this.firstChild);
                    }
                    this.parentNode.replaceChild(fragment, this);
                };

                Element.prototype.insertAfter = function (newNode)
                {
                    this.parentNode.insertBefore(newNode, this.nextSibling);
                };

                Array.prototype.indexOf || (Array.prototype.indexOf = function (d, e)
                {
                    var a;
                    if (null == this) throw new TypeError('"this" is null or not defined');
                    var c = Object(this),
                        b = c.length >>> 0;
                    if (0 === b) return -1;
                    a = +e || 0;
                    Infinity === Math.abs(a) && (a = 0);
                    if (a >= b) return -1;
                    for (a = Math.max(0 <= a ? a : b - Math.abs(a), 0); a < b;)
                    {
                        if (a in c && c[a] === d) return a;
                        a++
                    }
                    return -1
                });

                // Constants
                var TEXT_NODE_TYPE = 3;

                // Private(ish)
                function wrapVerseById(verseId)
                {
                    var verses = document.querySelectorAll('#bible-passages span.vn');

                    var firstVerse = null;
                    var endVerse = null;
                    for (index = verseId - 1; index < verses.length; index++)
                    {
                        var verse = verses[index];

                        if (firstVerse)
                        {
                            endVerse = verse;
                            break;
                        }

                        if (verse.getAttribute('ref') === verseId)
                        {
                            firstVerse = verse;
                        }
                    }

                    var elementWrapVerseData = {
                        type: 'span',
                        className: 'verse_' + verseId,
                        style: {
                            textDecoration: 'none'
                        }
                    };

                    wrapContainersAndSubelementsWithElementData(elementWrapVerseData, firstVerse, endVerse, 'ignoreSpanWrappingElement');
                }

                function wrapVerseOnClick(event)
                {
                    event = window.e || event;

                    var HTMLTag = document.getElementsByTagName('html');
                    var isRTL = HTMLTag[0].getAttribute('dir') === 'rtl';

                    var closestVerses = findClosestElementBeforeAndAfterClickLocation(event, 'span', 'vn', isRTL);

                    if (closestVerses)
                    {
                        var startContainer = closestVerses.elementBefore;
                        var endContainer = closestVerses.elementAfter;

                        var verseNumber = startContainer.getAttribute('ref');

                        if (!handleVersesAlreadyWrapped(verseNumber, true))
                        {

                            var elementWrapVerseData = {
                                type: 'span',
                                className: 'verse_' + verseNumber,
                                style: {
                                    textDecoration: 'underline'
                                }
                            };

                            wrapContainersAndSubelementsWithElementData(elementWrapVerseData, startContainer, endContainer, 'ignoreSpanWrappingElement');
                        }

                        var verses = document.querySelectorAll('#bible-passages span.verse_' + verseNumber);

                        // Verses never been underlined OR verses have just updated to be underlined then callback with adding to action list.
                        if (verses.length > 0 && (verses[0].style.textDecoration === 'undefined' || verses[0].style.textDecoration === 'underline'))
                        {
                            selectedVerses.push(verseNumber);
                        }
                        // there are no verses with that class. OR verses now have a tag of 'none'
                        else
                        {
                            var index = selectedVerses.indexOf(verseNumber);
                            if (index > -1)
                            {
                                selectedVerses.splice(index, 1);
                            }
                        }
                    } else
                    {
                    }
                }

                function wrapContainersAndSubelementsWithElementData(elementData, startContainer, endContainer, ignoringClass)
                {
                    var tempSpan = null;
                    // Last Span in the list. Add a temp element at the end to highlight to
                    if (!endContainer)
                    {
                        tempSpan = document.createElement('span');
                        startContainer.parentElement.appendChild(tempSpan);
                        endContainer = tempSpan;
                    }

                    endContainer.addClass('ignoreSpanWrappingElement');

                    addElementDataAroundContainers(elementData, startContainer, endContainer, 'ignoreSpanWrappingElement');

                    if (tempSpan)
                    {
                        tempSpan.remove();
                    }

                    endContainer.removeClass('ignoreSpanWrappingElement');
                }

                function findClosestElementBeforeAndAfterClickLocation(event, elementTagName, className, isRTL)
                {
                    var closestElementsToEvent = {
                        elementBefore: null,
                        elementAfter: null
                    };

                    var elements = document.querySelectorAll(elementTagName + '.' + className);

                    for (index = 0; index < elements.length; index++)
                    {
                        var element = elements[index];
                        elementOccursBeforeEvent(closestElementsToEvent, event, element, isRTL);

                        if (closestElementsToEvent.elementAfter)
                        {
                            break;
                        }
                    }

                    return closestElementsToEvent;
                }

                function elementOccursBeforeEvent(closestElementsToEvent, event, element, isRTL)
                {
                    var elementRect = element.getBoundingClientRect();

                    // element's top is below the click (this means that the line you click on should have the span as the next heighest item)
                    // Need to also make sure that the left side of it is less than the X else it was the span
                    // before that was clicked and not the next one on the same line
                    if (elementRect.top < event.clientY)
                    {
                        // This span falls on the exact line of the click. Check if it is to the left or right of the click depending on language text orientation (LTR or RTL)
                        if (elementRect.top < event.clientY && elementRect.bottom > event.clientY)
                        {
                            if (isRTL)
                            {
                                if (elementRect.left > event.clientX)
                                {
                                    closestElementsToEvent.elementBefore = element;
                                }
                                // If we are on the same line as the click and the element is not in the proper position then
                                // there is no way any other element is in the proper position so we can break and return now.
                                else
                                {
                                    closestElementsToEvent.elementAfter = element;
                                }
                            } else
                            {
                                if (elementRect.left < event.clientX)
                                {
                                    closestElementsToEvent.elementBefore = element;
                                }
                                // If we are on the same line as the click and the element is not in the proper position then
                                // there is no way any other element is in the proper position so we can break and return now.
                                else
                                {
                                    closestElementsToEvent.elementAfter = element;
                                }
                            }
                        }
                        // An element along the way down that could be the nearest.
                        else
                        {
                            closestElementsToEvent.elementBefore = element;
                        }
                    } else
                    {
                        closestElementsToEvent.elementAfter = element;
                    }
                }

                function addElementDataAroundContainers(elementData, startContainer, endContainer, ignoreClassName)
                {

                    var containersRange = document.createRange();
                    containersRange.setStart(startContainer, 0);
                    containersRange.setEnd(endContainer, 0);

                    // check if start & end are same, if so split end node
                    if (startContainer === endContainer)
                    {
                        // splits node at startOffset, returns text after the split location
                        var splitNode = startContainer.splitText(containersRange.startOffset);
                        // modifies splitNode, returns text node after split as temp
                        var temp = splitNode.splitText(containersRange.endOffset);

                        var newNode = document.createElement(elementData.type);
                        newNode.className = elementData.className;
                        newNode.style.textDecoration = elementData.style.textDecoration;

                        // insert newNode before splitNode in the parentNode
                        var insertElement = startContainer.parentNode.insertBefore(newNode, splitNode);
                        // move splitNode into (as child of ) insertElement ( which is newNode )
                        insertElement.appendChild(splitNode);
                    } else
                    {
                        // ensure offsets are text node positions
                        var startOffset = startContainer.nodeType === TEXT_NODE_TYPE ? containersRange.startOffset : 0;
                        var endOffset = endContainer.nodeType === TEXT_NODE_TYPE ? containersRange.endOffset : 0;

                        if (startOffset > 0)
                        {
                            var firstTextNode = startContainer.splitText(startOffset);
                            containersRange.setStart(firstTextNode, 0);
                        }
                        if (endOffset > 0)
                        {
                            var endTextNode = endContainer.splitText(endOffset);
                        }

                        var textNodes = [];
                        textNodes = getTextNodes(containersRange.commonAncestorContainer, textNodes, ignoreClassName);
                        // textNodes[0].parentNode.removeChild(textNodes[0]);
                        var realNodes = [];
                        for (var i = 0; i < textNodes.length; i++)
                        {
                            if (rangeIntersectsNode(containersRange, textNodes[i]))
                            {
                                realNodes.push(textNodes[i]);
                            }
                        }
                        wrapTextNodes(elementData, realNodes, 0, realNodes.length - 1);
                    }
                }

                function wrapTextNodes(elementData, textNodes, start, end)
                {
                    var numNodes = end + 1 - start;
                    for (var i = 0; i < numNodes; i++)
                    {
                        // create node element to wrap text node
                        var newNode = document.createElement(elementData.type);
                        newNode.className = elementData.className;
                        newNode.style.textDecoration = elementData.style.textDecoration;

                        var insertElement = textNodes[i].parentNode.insertBefore(newNode, textNodes[i]);
                        // move splitNode into (as child of ) insertElement ( which is newNode )
                        insertElement.appendChild(textNodes[i]);
                    }
                }

                function rangeIntersectsNode(range, node)
                {
                    var nodeRange;
                    if (range.intersectsNode)
                    {
                        return range.intersectsNode(node);
                    } else
                    {
                        nodeRange = node.ownerDocument.createRange();
                        try
                        {
                            nodeRange.selectNode(node);
                        } catch (e)
                        {
                            nodeRange.selectNodeContents(node);
                        }
                        return range.compareBoundaryPoints(Range.END_TO_START, nodeRange) === -1 &&
                            range.compareBoundaryPoints(Range.START_TO_END, nodeRange) === 1;
                    }
                }

                // gets all text nodes inside container node and returns array nodes
                function getTextNodes(node, nodes, ignoreClassName)
                {
                    if (node.nodeType === TEXT_NODE_TYPE)
                    {
                        nodes.push(node);
                    }
                    var children = node.childNodes;
                    for (var i = 0; i < children.length; i++)
                    {
                        var child = children[i];
                        // Check if child is an ignored element.
                        if (child && (!child.hasClass || !child.hasClass(ignoreClassName)))
                        {
                            getTextNodes(child, nodes, ignoreClassName);
                        }
                    }
                    return nodes;
                }

                function handleVersesAlreadyWrapped(verseId, underline)
                {
                    var versesAlreadyWrapped = document.querySelectorAll('#bible-passages span.verse_' + verseId);

                    if (versesAlreadyWrapped)
                    {
                        for (index = 0; index < versesAlreadyWrapped.length; index++)
                        {
                            var verse = versesAlreadyWrapped[index];

                            if (underline && verse.dataset.hasHighlight === '1')
                            {
                                if (verse.style.textDecoration === 'none')
                                {
                                    verse.style.textDecoration = 'underline';
                                } else
                                {
                                    verse.style.textDecoration = 'none';
                                }
                            } else
                            {
                                verse.removeElementWhileRetainingChildren();
                            }
                        }
                    }
                    return versesAlreadyWrapped.length > 0;
                }

                // Update Bible view
                updateBibleElements = function (bibleBook, bibleChapter, bibleTemplate, bibleElement)
                {

                    if (bibleElement === '#bible-passages')
                    {
                        animateBibleUpdateOut();
                    }

                    $.ajax({
                        type: "POST",
                        url: "/app/themes/newlife/lib/" + bibleTemplate + ".php?current_book=" + bibleBook + "&current_chapter=" + bibleChapter,
                        data: { "book": bibleBook, "chapter": bibleChapter },
                        dataType: "html",
                        complete: function ()
                        {
                            // Update book and chapter cookie values
                            Cookies.set('bibleBook', bibleBook);
                            Cookies.set('bibleChapter', bibleChapter);
                            Cookies.set('bibleVerse', 1);
                        },
                        success: function (result)
                        {
                            $(bibleElement).html(result);
                            if (bibleElement === '#bible-passages')
                            {
                                // Assign current-book ID to list that matches to current view
                                $('[name="book-' + bibleBook + '"]').attr('id', 'current-book');

                                // Wrap inline notes
                                $('.tn').wrapInner('<span class="inline-note"></span>');

                                // Apply red letter option
                                if (Cookies.get('bibleRedLetter') === 'true')
                                {
                                    $('chapter .red').removeClass('red-off');
                                    $('#red-letter-control').prop('checked', true);
                                } else if (Cookies.get('bibleRedLetter') === 'false')
                                {
                                    $('chapter .red').addClass('red-off');
                                    $('#red-letter-control').prop('checked', false);
                                }

                                // Reset bible actions and verse selections
                                resetBibleActions(true, true);

                                // Apply font size option
                                if (Cookies.get('bibleTextSize') === 'small')
                                {
                                    changeBibleTextSize('small', '16px', '20px');
                                } else if (Cookies.get('bibleTextSize') === 'medium')
                                {
                                    changeBibleTextSize('medium', '18px', '24px');
                                } else if (Cookies.get('bibleTextSize') === 'large')
                                {
                                    changeBibleTextSize('large', '22px', '28px');
                                }

                                animateBibleUpdateIn();

                            }
                        }
                    });
                };

                animateBibleUpdateOut = function ()
                {
                    $('#bible-passages').addClass('loading');
                    $('#bible-content .spinner').addClass('show-spinner');
                }

                animateBibleUpdateIn = function ()
                {
                    $('#bible-content .spinner').removeClass('show-spinner');
                    $('#bible-passages').scrollTop(0).removeClass('inactive').removeClass('loading');
                }

                updateBibleView = function (bibleBook, bibleChapter, bibleVerse)
                {
                    bibleVerse = bibleVerse ? bibleVerse : 1;
                    // Update book navigation
                    updateBibleElements(bibleBook, bibleChapter, 'bible-old-testament-books', '#bible-old-testament-books');
                    updateBibleElements(bibleBook, bibleChapter, 'bible-new-testament-books', '#bible-new-testament-books');

                    // Update chapter navigation
                    updateBibleElements(bibleBook, bibleChapter, 'bible-primary-nav', '#bible-primary-nav-wrap');
                    updateBibleElements(bibleBook, bibleChapter, 'bible-secondary-nav', '#bible-secondary-nav');

                    // Update passage
                    updateBibleElements(bibleBook, bibleChapter, 'bible-passages', '#bible-passages');

                    if (APP_DATA.current_user_id !== '0')
                    {
                        $.ajax({
                            type: "GET",
                            contentType: 'application/json',
                            url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            },
                            success: function (result)
                            {
                                if (result.bible_format !== null)
                                {
                                    var verses = result.bible_format[bibleBook + '.' + bibleChapter];
                                    $.each(verses, function (key, value)
                                    {
                                        if ("highlight" in value && value.highlight !== "[DELETED]")
                                        {
                                            highlightVerse(key, value.highlight);
                                        }
                                        if ("note" in value && value.note !== "[DELETED]")
                                        {
                                            noteOnVerse(key, value.note);
                                        }
                                    });
                                }
                            },
                        });
                    }
                    setTimeout(function ()
                    {
                        document.getElementById("bible-passages").scrollTop = $("span.vn[ref='" + bibleVerse + "']")[0].offsetTop;
                    }, 500);
                };

                // Build JSON object for AJAX request update bible_format user meta
                // NOTE: Allows book, chapter and verse keys to be defined by variables
                buildBibleFormatJson = function (bookChapter, selectedVerses, type, value)
                {
                    var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                    var verseObject = {};
                    for (var index = 0; index < selectedVerses.length; index++)
                    {
                        // Force conversion to a string as it was using index with verseNumber
                        var verseNumber = "" + selectedVerses[index];
                        var jsonObj = {};
                        jsonObj[type] = value;
                        jsonObj[type + '_last_update'] = timestamp;
                        verseObject[verseNumber] = jsonObj;
                    }
                    var bookChapterObject = {};
                    bookChapterObject[bookChapter] = verseObject;
                    var bibleFormatObject = {};
                    bibleFormatObject.bible_format = bookChapterObject;
                    return JSON.stringify(bibleFormatObject);
                };

                showBibleActions = function (event)
                {
                    var $element = $('#bible-actions');
                    var $offset = $('#bible').offset();
                    var relativeX = (event.pageX - ($element.width() / 2 + $offset.left));
                    var relativeY = (event.pageY + (25 - $offset.top));
                    // Show at localtion of click event
                    $element.removeClass('hide-popover').addClass('show-popover').css({ 'top': relativeY, 'left': relativeX });
                };

                resetBibleActions = function (hideSecondaryControls, clearVerseSelection)
                {
                    // Hide bible actions popover
                    $('#bible-actions').addClass('hide-popover').removeClass('show-popover');
                    if (hideSecondaryControls === true)
                    {
                        $('#bible-actions-highlight-colours').hide();
                        $('#bible-actions-note-form').hide();
                        $('#bible-actions-share-options').hide();
                        // Reset the note input
                        $('#bible-actions-note-form form')[0].reset();
                        // Show primary controls
                        $('#bible-actions-primary-buttons').show();
                    }
                    // Reset selected verses array and text decoration
                    if (clearVerseSelection === true)
                    {
                        selectedVerses = [];
                        $('chapter p span').css('text-decoration', 'none');
                    }
                };

                showBiblePopover = function (event, name, content)
                {
                    var $element = $('#bible-popover');
                    var $offset = $('#bible').offset();
                    var relativeX = (event.pageX - ($element.width() / 2 + $offset.left));
                    var relativeY = (event.pageY + (25 - $offset.top));
                    // Show at localtion of click event
                    $element.removeClass('hide-popover').addClass('show-popover').css({ 'top': relativeY, 'left': relativeX });
                    // Add name and content to popover
                    $('#bible-popover .popover-name').html(name);
                    $('#bible-popover .popover-content').html(content);
                };

                resetBiblePopover = function ()
                {
                    // Hide bible actions popover
                    $('#bible-popover').addClass('hide-popover').removeClass('show-popover');
                };

                showJoinPrompt = function ()
                {
                    BootstrapDialog.show({
                        title: STRING.join_modal_title,
                        message: STRING.join_modal_message,
                        animate: true,
                        closable: true,
                        closeByBackdrop: true,
                        closeByKeyboard: true,
                        buttons: [{
                            label: STRING.join_modal_button_join,
                            cssClass: 'btn-lg btn-primary',
                            action: function ()
                            {
                                window.location.href = "/join";
                            }
                        }, {
                            label: STRING.join_modal_button_sign_in,
                            cssClass: 'btn-lg btn-default',
                            action: function ()
                            {
                                window.location.href = "/sign-in";
                            }
                        }]
                    });
                };

                buildBibleSidebar = function (result, openCurrent, type)
                {
                    // Inject HTML from JSON object
                    $('#bible-user-lists').html(result.bible_sidebar);
                    // Perform list wrapping and open current book list
                    wrapSidebarUserLists();
                    if (openCurrent === true)
                    {
                        openCurrentBookList();
                    }
                    if (type === 'highlight')
                    {
                        $('#bible-highlights-list .bible-sidebar-list-name').nextUntil('.bible-sidebar-list-name').slideDown(300);
                        $('#bible-highlights-list .bible-sidebar-list-name').addClass('open');
                    } else if (type === 'note')
                    {
                        $('#bible-notes-list .bible-sidebar-list-name').nextUntil('.bible-sidebar-list-name').slideDown(300);
                        $('#bible-notes-list .bible-sidebar-list-name').addClass('open');
                    }
                };

                wrapSidebarUserLists = function ()
                {
                    // Expand and collapse Bible sidebar Highlights and Notes lists
                    var $staticListHeader = $('#bible-highlights-list .bible-sidebar-list-name, #bible-notes-list .bible-sidebar-list-name');
                    $staticListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        // if ($(this).siblings().hasClass('open')) {
                        //   $(this).removeClass('open');
                        // }
                    });
                    $staticListHeader.click(function ()
                    {
                        if (APP_DATA.current_user_id !== '0')
                        {
                            // User is logged in and has unique ID number
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        } else
                        {
                            // User is not logged in (ID number is '0')
                            $(this).removeClass('open');
                            showJoinPrompt();
                        }
                    });

                    // Expand and collapse Bible sidebar book lists
                    var $bibleSidebarBookHeader = $('#bible-highlights-list .bible-sidebar-book-name, #bible-notes-list .bible-sidebar-book-name');
                    $bibleSidebarBookHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-book-name').wrapAll('<dd class="bible-sidebar-book-items"><ul class="bible-sidebar-verses"></ul></dd>');
                    });
                    $bibleSidebarBookHeader.click(function ()
                    {
                        // Toggle open state
                        $(this).nextUntil('.bible-sidebar-book-name').slideToggle('fast');
                        // Toggle open class
                        $(this).toggleClass('open');
                        // Collapse other lists
                        $bibleSidebarBookHeader.not(this).nextUntil('.bible-sidebar-book-name').slideUp('fast');
                        // Set other lists as closed
                        $bibleSidebarBookHeader.not(this).removeClass('open');
                    });

                    // Remove empty book lists
                    var emptyBibleBookList = $('.bible-sidebar-book:not(:has(.bible-sidebar-book-items))');
                    emptyBibleBookList.each(function ()
                    {
                        $(this).remove();
                    });

                    // Show message if user has deleted all highlights or hasn't added any
                    if ($('#bible-highlights-list .bible-sidebar-list-items').length > 0)
                    {
                        var emptyHighlightsList = $('#bible-highlights-list .bible-sidebar-list-items:not(:has(.bible-sidebar-book-items))');
                        emptyHighlightsList.each(function ()
                        {
                            $(this).html('<span class="bible-empty-list-message">' + STRING.bible_no_highlights_message + '</span>');
                        });
                    } else
                    {
                        if (APP_DATA.current_user_id !== "0")
                        {
                            $('#bible-highlights-list').append('<dd class="bible-sidebar-list-items"><span class="bible-empty-list-message">' + STRING.bible_no_highlights_message + '</span><dd>');
                        } else
                        {
                            return false;
                        }
                    }

                    // Show message if user has deleted all notes or hasn't added any
                    if ($('#bible-notes-list .bible-sidebar-list-items').length > 0)
                    {
                        var emptyNotesList = $('#bible-notes-list .bible-sidebar-list-items:not(:has(.bible-sidebar-book-items))');
                        emptyNotesList.each(function ()
                        {
                            $(this).html('<span class="bible-empty-list-message">' + STRING.bible_no_notes_message + '</span>');
                        });
                    } else
                    {
                        if (APP_DATA.current_user_id !== "0")
                        {
                            $('#bible-notes-list').append('<dd class="bible-sidebar-list-items"><span class="bible-empty-list-message">' + STRING.bible_no_notes_message + '</span><dd>');
                        }
                    }
                };

                wrapSidebarStaticLists = function ()
                {
                    // Expand and collapse Bible sidebar Highlights and Notes lists
                    var $whereToFindListHeader = $('#where-to-find-list .bible-sidebar-list-name');
                    $whereToFindListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        $(this).removeClass('open');
                    });
                    $whereToFindListHeader.click(function ()
                    {
                        if (APP_DATA.current_user_id !== '0')
                        {
                            // User is logged in and has unique ID number
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        } else
                        {
                            // User is not logged in (ID number is '0')
                            $(this).removeClass('open');
                            showJoinPrompt();
                        }
                    });

                    var $searchListHeader = $('#bible-search-list .bible-sidebar-list-name');
                    $searchListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        $(this).removeClass('open');
                    });
                    $searchListHeader.click(function ()
                    {
                        $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        $(this).nextUntil('.bible-sidebar-list-name').toggleClass('open');
                    });

                    var $optionsListHeader = $('#bible-options-list .bible-sidebar-list-name');
                    $optionsListHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-list-name').wrapAll('<dd class="bible-sidebar-list-items"></dd>');
                        $(this).nextUntil('.bible-sidebar-list-name').hide();
                        $(this).removeClass('open');
                    });
                    $optionsListHeader.click(function ()
                    {
                        if (APP_DATA.current_user_id !== '0')
                        {
                            // User is logged in and has unique ID number
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        } else
                        {
                            // User is not logged in (ID number is '0')
                            $(this).toggleClass('open').nextUntil('.bible-sidebar-list-name').slideToggle('fast');
                        }
                    });

                    // Expand and collapse Bible sidebar book lists
                    var $bibleSidebarBookHeader = $('#bible-options-list .bible-sidebar-book-name, #where-to-find-list .bible-sidebar-book-name');
                    $bibleSidebarBookHeader.each(function ()
                    {
                        $(this).nextUntil('.bible-sidebar-book-name').wrapAll('<dd class="bible-sidebar-book-items"><ul class="bible-sidebar-verses"></ul></dd>');
                        $(this).nextUntil('.bible-sidebar-book-name').hide();
                        $(this).removeClass('open');
                    });
                    $bibleSidebarBookHeader.click(function ()
                    {
                        // Toggle open state
                        $(this).nextUntil('.bible-sidebar-book-name').slideToggle('fast');
                        // Toggle open class
                        $(this).toggleClass('open');
                        // Collapse other lists
                        $bibleSidebarBookHeader.not(this).nextUntil('.bible-sidebar-book-name').slideUp('fast');
                        // Set other lists as closed
                        $bibleSidebarBookHeader.not(this).removeClass('open');
                    });
                };

                openCurrentBookList = function ()
                {
                    // Expand and collapse Bible sidebar book lists
                    var $bibleUserListSubHeader = $('#bible-highlights-list .bible-sidebar-book-name, #bible-notes-list .bible-sidebar-book-name');
                    $bibleUserListSubHeader.each(function ()
                    {
                        // Show book list contents if it matches the current book in view
                        if ($(this).closest("#current-book").length === 1)
                        {
                            $(this).nextUntil('.bible-sidebar-book-name').slideDown('fast');
                            $(this).addClass('open');
                        } else
                        {
                            $(this).nextUntil('.bible-sidebar-book-name').hide();
                        }
                    });
                    // Switch list contents from "display: none;" to "display: block;"
                    $('.list-wrapper').show();
                };

                changeBibleTextSize = function (size, fontSize, lineHeight)
                {
                    $('#bible-passages chapter p').css({ 'font-size': fontSize, 'line-height': lineHeight });
                    $('.bible-text-size').removeClass('on');
                    $('#bible-text-' + size).addClass('on');
                    Cookies.set('bibleTextSize', size);
                };

                scrollToBibleTop = function ()
                {
                    // Scroll to top of Bible container
                    $('html, body').animate({
                        scrollTop: $(".content").offset().top
                    }, 500);
                };

                highlightVerse = function (verseId, highlightColor)
                {
                    var verses = document.querySelectorAll('#bible-passages span.verse_' + verseId);

                    if (verses.length === 0)
                    {
                        wrapVerseById(verseId);
                        // reload verses with updated tags
                        verses = document.querySelectorAll('#bible-passages span.verse_' + verseId);
                    }

                    if (highlightColor !== null)
                    {
                        $('span.verse_' + verseId + ':gt(0)').css('background-color', 'rgba(' + highlightColor + ')');
                    } else
                    {
                        $('span.verse_' + verseId + ':gt(0)').css('background-color', 'transparent');
                    }

                    for (index = 0; index < verses.length; ++index)
                    {
                        verse = verses[index];
                        verse.dataset.hasHighlight = '1';
                    }
                };

                noteOnVerse = function (verseId, verseNote)
                {
                    var noteVerses = document.querySelectorAll('#bible-passages span.verse_' + verseId);

                    if (noteVerses.length === 0)
                    {
                        wrapVerseById(verseId);
                        // reload verses with updated tags
                        noteVerses = document.querySelectorAll('#bible-passages span.verse_' + verseId);
                    }

                    if (verseNote !== null)
                    {
                        $('span.verse_' + verseId).eq(1).prepend('<button class="note-on-verse" data-verse="' + verseId + '" value="' + escapeHtml(verseNote) + '"><span class="sr-only">' + STRING.bible_view_verse + '</span><span class="icon icon-note" aria-hidden="true"></span></button>');
                    } else
                    {
                        $('button[data-verse="' + verseId + '"]').remove();
                    }
                };

                // BIBLE VIEW
                // Set initial view
                $(document).ready(function ()
                {
                    updateBibleView(bibleBook, bibleChapter, 1);
                    wrapSidebarStaticLists();
                    wrapSidebarUserLists();
                    openCurrentBookList();
                    updateHistory(bibleBook, bibleChapter);
                });

                // Set view when user uses browser back/forward buttons
                window.addEventListener('popstate', function (e)
                {
                    updateBibleView(e.state.book, e.state.chapter, 1);
                    openCurrentBookList();
                });

                $('#bible-passages').scroll(function ()
                {
                    resetBibleActions(false, false);
                    resetBiblePopover();
                });

                // When Bible book nav link is clicked...
                $(document).on('click', '.bible-book-navigation-link', function (e)
                {
                    e.preventDefault();
                    var bibleBook = $(this).val();
                    var bibleChapter = "1";
                    $('#bible-books').slideUp(200, function ()
                    {
                        updateBibleView(bibleBook, bibleChapter, 1);
                        updateHistory(bibleBook, bibleChapter);
                    });
                });

                // When Bible chapter nav link is clicked...
                $(document).on('click', '.bible-chapter-nav-link', function (e)
                {
                    e.preventDefault();
                    var bibleBook = $(this).data("book");
                    var bibleChapter = $(this).data("chapter");
                    var bibleVerse = $(this).data("verse") ? $(this).data("verse") : 1
                    if ($('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideUp(200, function ()
                        {
                            updateBibleView(bibleBook, bibleChapter, bibleVerse);
                            updateHistory(bibleBook, bibleChapter);

                        });
                    } 
                    else if ($('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideUp(200, function ()
                        {
                            updateBibleView(bibleBook, bibleChapter, bibleVerse);
                            updateHistory(bibleBook, bibleChapter);

                        });
                    } 
                    else
                    {
                        updateBibleView(bibleBook, bibleChapter, bibleVerse);
                        updateHistory(bibleBook, bibleChapter);
                    }
                });

                // BIBLE NAVIGATION

                $(document).on('click', '#bible-secondary-nav .bible-chapter-nav-link', function ()
                {
                    scrollToBibleTop();
                });

                // Bible book heading is clicked
                $(document).on('click', '#bible-book-heading', function ()
                {
                    if ($('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideUp('fast', function ()
                        {
                            $('#bible-chapters-nav').removeClass('open');
                            $('#bible-chapter-heading').removeClass('open');
                        });
                    }
                    if ($('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideUp('fast', function ()
                        {
                            $('#bible-books').removeClass('open');
                            $('#bible-passages').removeClass('inactive');
                            $('#bible-book-heading').removeClass('open');
                        });
                    }
                    else if (!$('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideDown('fast', function ()
                        {
                            $('#bible-books').addClass('open');
                            $('#bible-passages').addClass('inactive');
                            $('#bible-book-heading').addClass('open');
                        });
                    }
                });

                // Bible chapter heading is clicked
                $(document).on('click', '#bible-chapter-heading', function ()
                {
                    if ($('#bible-books').hasClass('open'))
                    {
                        $('#bible-books').slideUp('fast', function ()
                        {
                            $('#bible-books').removeClass('open');
                            $('#bible-book-heading').removeClass('open');
                        });
                    }
                    if ($('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideUp('fast', function ()
                        {
                            $('#bible-chapter-heading').removeClass('open');
                            $('#bible-chapters-nav').removeClass('open');
                            $('#bible-passages').removeClass('inactive');
                        });
                    }
                    else if (!$('#bible-chapters-nav').hasClass('open'))
                    {
                        $('#bible-chapters-nav').slideDown('fast', function ()
                        {
                            $('#bible-chapter-heading').addClass('open');
                            $('#bible-chapters-nav').addClass('open');
                            $('#bible-passages').addClass('inactive');
                        });
                    }
                });

                // Bible book nav Old Testment header is clicked
                $(document).on('click', '.bible-old-testament-header', function ()
                {
                    $('#bible-old-testament-nav').removeClass('hidden');
                    $('#bible-old-testament-nav').slideToggle('fast');
                    $('#bible-new-testament-nav').slideUp('fast');
                    $('.bible-new-testament-header').removeClass('open');
                    $(this).toggleClass('open');
                });

                // Bible book nav New Testment header is clicked
                $(document).on('click', '.bible-new-testament-header', function ()
                {
                    $('#bible-new-testament-nav').removeClass('hidden');
                    $('#bible-new-testament-nav').slideToggle('fast');
                    $('#bible-old-testament-nav').slideUp('fast');
                    $('.bible-old-testament-header').removeClass('open');
                    $(this).toggleClass('open');
                });

                // BIBLE ACTIONS

                $(document).on('click', '#bible-passages', function ()
                {
                    if ($('#bible-passages').hasClass('inactive'))
                    {
                        $('#bible-books').slideUp('fast');
                        $('#bible-books').removeClass('open');
                        $('#bible-book-heading').removeClass('open');
                        $('#bible-chapters-nav').slideUp('fast');
                        $('#bible-chapters-nav').removeClass('open');
                        $('#bible-chapter-heading').removeClass('open');
                        $('#bible-passages').removeClass('inactive');
                    }
                });

                // Bible body text is clicked
                $(document).on('click', 'chapter p:not(.subhead, .subhead-ch)', function (event)
                {
                    if (!$('#bible-passages').hasClass('inactive'))
                    {
                        wrapVerseOnClick(event);
                        // Show Bible actions popover
                        if (selectedVerses.length > 0)
                        {
                            resetBiblePopover();
                            showBibleActions(event);
                        }
                    }
                });

                // Hide Bible actions when any other element is clicked
                $(document).mouseup(function (e)
                {
                    var container = $('#bible-actions');
                    if (!container.is(e.target) && container.has(e.target).length === 0)
                    {
                        resetBibleActions(false, false);
                    }
                });

                $(document).on('mouseover', '.note-on-verse', function (event)
                {
                    resetBibleActions(true, true);
                    showBiblePopover(event, STRING.bible_popover_title_note, $(this).val());
                });

                $(document).on('mouseout', '.note-on-verse', function ()
                {
                    resetBiblePopover();
                });

                // Hide Bible actions when any other element is clicked
                $(document).mouseup(function (e)
                {
                    var container = $('#bible-popover');
                    if (!container.is(e.target) && container.has(e.target).length === 0)
                    {
                        resetBiblePopover();
                    }
                });

                // Close button is clicked
                $('.bible-actions-close').click(function ()
                {
                    resetBibleActions(true, false);
                });

                // Highlight button is clicked
                $('#bible-actions-highlight').click(function ()
                {
                    if (APP_DATA.current_user_id !== '0')
                    {
                        // User is logged in and has unique ID number
                        $('#bible-actions-primary-buttons').hide();
                        $('#bible-actions-highlight-colours').show();
                    } else
                    {
                        // User is not logged in (ID number is '0')
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    }
                });

                // Note button is clicked
                $('#bible-actions-note').click(function ()
                {
                    if (APP_DATA.current_user_id !== '0')
                    {
                        // User is logged in and has unique ID number
                        $('#bible-actions-primary-buttons').hide();
                        $('#bible-actions-buttons-note').show();
                        $('#bible-actions-note-form').show();
                        $('#bible-actions-note-input').focus();
                    } else
                    {
                        // User is not logged in (ID number is '0')
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    }
                });

                // Share button is clicked
                $('#bible-actions-share').click(function ()
                {
                    if (APP_DATA.current_user_id !== '0')
                    {
                        // User is logged in and has unique ID number
                        $('#bible-actions-primary-buttons').hide();
                        $('#bible-actions-share-options').show();
                    } else
                    {
                        // User is not logged in (ID number is '0')
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    }
                });

                // Add highlight colour selection is clicked
                $(document).on('click', '.bible-highlight-colour', function ()
                {
                    var book = Cookies.get('bibleBook');
                    var chapter = Cookies.get('bibleChapter');
                    var bookChapter = book + '.' + chapter;
                    var type = 'highlight';
                    var value = $(this).val();
                    var verses = selectedVerses;

                    $.ajax({
                        type: "POST",
                        context: this,
                        contentType: 'application/json',
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me?filter=bible_sidebar',
                        data: buildBibleFormatJson(bookChapter, verses, type, value),
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        complete: function ()
                        {
                            $.each(verses, function (index, verse)
                            {
                                if (value === "[DELETED]")
                                {
                                    value = null;
                                }
                                highlightVerse(verse, value);
                            });
                            resetBibleActions(true, true);
                        },
                        success: function (result)
                        {
                            buildBibleSidebar(result, true, type);
                        }
                    });
                });

                // Save note button is clicked
                $('#bible-actions-save-note').click(function ()
                {
                    var book = Cookies.get('bibleBook');
                    var chapter = Cookies.get('bibleChapter');
                    var bookChapter = book + '.' + chapter;
                    var verses = selectedVerses.sort();
                    var type = 'note';
                    var value = $('#bible-actions-note-input').val();
                    $.ajax({
                        type: "POST",
                        context: this,
                        contentType: 'application/json',
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me?filter=bible_sidebar',
                        data: buildBibleFormatJson(bookChapter, [verses[0]], type, escapeHtml(value)),
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        success: function (result)
                        {
                            resetBibleActions(true, true);
                            buildBibleSidebar(result, true, type);
                            noteOnVerse(verses[0], escapeHtml(value));
                        }
                    });
                });

                $('#search_query').keyup(function(e) {
                    if(e.which == 13)
                        $('#bible-actions-search').click();
                });

                // Search button
                $('#bible-actions-search').click(function (e)
                {
                    selectedVerses = selectedVerses.sort();
                    var query = encodeURIComponent($("#search_query").val());
                    $.ajax({
                        type: "GET",
                        url: "/app/themes/newlife/lib/bible-sidebar-search-results.php?query=" + query,
                        dataType: "json",
                        success: function (result)
                        {
                            console.log(result);
                            $("#bible-search-list ul.bible-sidebar-verses").html(result.responseText);
                            searchTotal = result.responseText.split("</li>").length - 1;
                            $("div.totalCounts").removeClass("hideBoth");
                            $("div.totalCounts").removeClass("pageMove");
                            $("div.totalCounts").removeClass("pageEnd");
                            $("ul.bible-sidebar-verses li.pages").hide();
                            $("ul.bible-sidebar-verses li.page0").show();
                            searchPage = 0;
                            prevPage = 0;
                            
                            if (searchTotal)
                            {
                                $("div.totalCounts p").html("1-" + (searchTotal > 4 ? 5 : searchTotal) + " of " + searchTotal + " results");
                            }
                            else
                            {
                                $("div.totalCounts p").html("0 results");
                            }
                            if (searchTotal <= 5)
                            {
                                $("div.totalCounts").addClass("hideBoth");
                            }
                            $("div.totalCounts").css('display', 'flex');
                        },
                        error: function (result)
                        {
                            console.log(result);
                            if (result.status == 200)
                            {
                                $("#bible-search-list ul.bible-sidebar-verses").html(result.responseText);
                                searchTotal = result.responseText.split("</li>").length - 1;
                            }
                            $("div.totalCounts").removeClass("hideBoth");
                            $("div.totalCounts").removeClass("pageMove");
                            $("div.totalCounts").removeClass("pageEnd");
                            $("ul.bible-sidebar-verses li.pages").hide();
                            $("ul.bible-sidebar-verses li.page0").show();
                            searchPage = 0;
                            prevPage = 0;

                            if (searchTotal)
                            {
                                $("div.totalCounts p").html("1-" + (searchTotal > 4 ? 5 : searchTotal) + " of " + searchTotal + " results");
                            }
                            else
                            {
                                $("div.totalCounts p").html("0 results");
                            }
                            if (searchTotal <= 5)
                            {
                                $("div.totalCounts").addClass("hideBoth");
                            }
                            $("div.totalCounts").css('display', 'flex');
                        }
                    });
                });

                $("div.totalCounts button").click(function(e){
                    var addPage = true;
                    if ($(this).hasClass('bible-nav-previous-toggle'))
                        addPage = false;
                    
                    prevPage = searchPage;
                    searchPage += addPage ? 1 : -1;
                    $("ul.bible-sidebar-verses li.pages").hide();
                    $("ul.bible-sidebar-verses li.page"+ searchPage).show();
                    if(prevPage == 0)
                    {
                        $("div.totalCounts").addClass("pageMove");
                    }
                    if (searchPage == 0)
                    {
                        $("div.totalCounts").removeClass("pageMove");
                    }
                    if((searchPage + 1) * 5 > searchTotal)
                    {
                        $("div.totalCounts").addClass("pageEnd");
                    }
                    else if ($("div.totalCounts").hasClass('pageEnd'))
                    {
                        $("div.totalCounts").removeClass("pageEnd");
                    }
                    $("div.totalCounts p").html( (searchPage*5+1) + "-" + ( (searchPage+1)*5) + " of " + searchTotal + " results");
                });

                // Share verse button is clicked
                $('.bible-share-verse').click(function (e)
                {
                    e.preventDefault();
                    var book = Cookies.get('bibleBook');
                    var chapter = Cookies.get('bibleChapter');
                    selectedVerses = selectedVerses.sort();
                    var verse = selectedVerses[0];
                    var type = $(this).val();
                    $.ajax({
                        type: "POST",
                        url: "/app/themes/newlife/lib/bible-share-verse.php?current_book=" + book + "&current_chapter=" + chapter + "&selected_verse=" + verse,
                        data: { "book": book, "chapter": chapter, "verse": verse },
                        dataType: "json",
                        success: function (result)
                        {
                            if (type === 'facebook')
                            {
                                FB.ui({
                                    method: 'share',
                                    display: 'popup',
                                    quote: result.book + ' ' + chapter + ':' + verse + ' – "' + result.passage + '"',
                                    href: APP_DATA.home_url,
                                }, function (response) { });
                            }
                            if (type === 'twitter')
                            {
                                var link = 'https://twitter.com/share?text=' + result.book + ' ' + chapter + ':' + verse + ' – "' + result.passage + '"' + '&via=GideonsCanada&url=' + APP_DATA.home_url;
                                var width = 840;
                                var height = 464;
                                var popupName = 'popup_' + width + 'x' + height;
                                var left = (screen.width - width) / 2;
                                var top = 100;
                                var params = 'width=' + width + ',height=' + height + ',location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,left=' + left + ',top=' + top;
                                window[popupName] = window.open(link, popupName, params);
                                if (window.focus)
                                {
                                    window[popupName].focus();
                                }
                                return true;
                            }
                        }
                    });
                });

                // Bible options sidebar - bookmark - view and add
                $(document).on('click', '#bible-actions-bookmark', function (event)
                {
                    if ($(this).hasClass('disabled'))
                    {
                        // User is not logged in, so prompt them to log in or join
                        resetBibleActions(true, true);
                        showJoinPrompt();
                    }
                    else if ($(this).hasClass('has-bookmark'))
                    {
                        // User has bookmark, so get their user object
                        $.ajax({
                            type: "GET",
                            url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            },
                            success: function (response)
                            {
                                // Render bookmarked Bible page and update history
                                var book = response.bible_bookmark.bookId;
                                var chapter = response.bible_bookmark.chapterId;
                                updateBibleView(book, chapter, 1);
                                updateHistory(book, chapter);
                            }
                        });
                    }
                    else
                    {
                        // User does not have bookmark, so bookmark current page
                        var book = Cookies.get('bibleBook');
                        var chapter = Cookies.get('bibleChapter');
                        var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                        var bookName = '';
                        $.ajax({
                            type: "POST",
                            url: "/app/themes/newlife/lib/bible-query-bookname.php?book=" + book,
                            data: { "book": book },
                            success: function (response)
                            {
                                bookName = response;
                            }
                        });
                        $.ajax({
                            type: "POST",
                            context: this,
                            url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                            data: { "bible_bookmark": { "bookId": book, "chapterId": chapter, "verseId": "1", "last_update": timestamp, "deleted": "" } },
                            beforeSend: function (xhr)
                            {
                                xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                            },
                            success: function (response)
                            {
                                resetBibleActions(true, true);
                                $(this).html('<span>' + bookName + ' ' + chapter + '</span>').addClass('has-bookmark').attr({
                                    'title': STRING.bible_view_bookmark,
                                    'data-book': book,
                                    'data-chapter': chapter
                                });
                                $('#bible-actions-bookmark-remove').addClass('has-bookmark').attr({
                                    'data-book': book,
                                    'data-chapter': chapter
                                });
                            }
                        });
                    }
                });

                // Bible options sidebar - bookmark - remove
                $(document).on('click', '#bible-actions-bookmark-remove', function (event)
                {
                    var book = $(this).attr('data-book');
                    var chapter = $(this).attr('data-chapter');
                    var timestamp = Math.floor(Date.now() / 1000).toFixed(6);
                    $.ajax({
                        type: "POST",
                        context: this,
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me',
                        data: { "bible_bookmark": { "bookId": book, "chapterId": chapter, "verseId": "1", "last_update": timestamp, "deleted": "[DELETED]" } },
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        success: function (response)
                        {
                            resetBibleActions(true, true);
                            $(this).removeClass('has-bookmark').attr({
                                'data-book': 'null',
                                'data-chapter': 'null'
                            });
                            $('#bible-actions-bookmark').html('<span>' + STRING.bible_bookmark + '</span>').removeClass('has-bookmark').attr({
                                'title': STRING.bible_bookmark,
                                'data-book': 'null',
                                'data-chapter': 'null'
                            });;
                        }
                    });
                });

                // Verse link on note or highlight is clicked
                $(document).on('click', '.verse-link', function ()
                {
                    var bibleBookChapter = $(this).parents('li').data("book-chapter") + '';
                    bibleBookChapter = bibleBookChapter.split('.');
                    var bibleBook = bibleBookChapter[0];
                    var bibleChapter = bibleBookChapter[1];
                    var verse = $(this).parents('li').data("verse") + '';
                    if (bibleBook !== Cookies.get('bibleBook') || bibleChapter !== Cookies.get('bibleChapter'))
                    {
                        // Is a different book and chapter combination or different chapter
                        updateBibleView(bibleBook, bibleChapter, 1);
                        updateHistory(bibleBook, bibleChapter);
                        scrollToBibleTop();
                    } else if (bibleBook === Cookies.get('bibleBook') && bibleChapter === Cookies.get('bibleChapter'))
                    {
                        // Is on this view (same book and chapter combination)
                        $('#bible-passages').scrollTop(0);
                        // // TODO: Scroll to verse instead!
                        // $('#bible-passages').animate({
                        //   scrollTop: $('span.verse_' + verse).offset().top - 200
                        // }, 500);
                        scrollToBibleTop();
                    }
                });

                // Mark bible sidebar list items as deleted
                $(document).on('click', '.delete-list-item', function ()
                {
                    var bibleBook = Cookies.get('bibleBook');
                    var bibleChapter = Cookies.get('bibleChapter');
                    var itemBookChapter = $(this).parents('li').data('book-chapter') + "";
                    var itemBookChapterArray = itemBookChapter.split(".");
                    var verse = [$(this).parents('li').data('verse')];
                    var type = $(this).parents('li').data('type');
                    var value = '[DELETED]';
                    $.ajax({
                        type: "POST",
                        context: this,
                        contentType: 'application/json',
                        url: APP_DATA.rest_root + 'wp-newlife/v2/users/me?filter=bible_sidebar',
                        data: buildBibleFormatJson(itemBookChapter, verse, type, value),
                        beforeSend: function (xhr)
                        {
                            xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                        },
                        complete: function ()
                        {
                            if (type === 'highlight')
                            {
                                highlightVerse(verse, null);
                            } else if (type === 'note')
                            {
                                noteOnVerse(verse, null);
                            }
                        },
                        success: function (result)
                        {
                            // Hide deleted notes list item then rebuild sidebar
                            $(this).parents('li').slideUp(100, function ()
                            {
                                setTimeout(function ()
                                {
                                    if ($(this).parents('li:only-child').length > 0)
                                    {
                                        buildBibleSidebar(result, false);
                                    } else
                                    {
                                        buildBibleSidebar(result, true);
                                    }
                                    $('#bible-' + type + 's-list .bible-sidebar-list-name').nextUntil('.bible-sidebar-list-name').show();
                                    $('#bible-' + type + 's-list .bible-sidebar-list-name').addClass('open');
                                }, 100);
                            });
                        },
                    });
                });

                // Show inline note (.tn) text in popover
                $(document).on('mouseover', '.tn', function (event)
                {
                    if (!$('#bible-passages').hasClass('inactive'))
                    {
                        showBiblePopover(event, STRING.bible_popover_title_translation, $(this).text());
                    }
                });

                $(document).on('mouseout', '.tn', function ()
                {
                    resetBiblePopover();
                });

                // Red letter control
                $(document).on('click', '#red-letter-option', function ()
                {
                    if ($('#red-letter-control').is(':checked'))
                    {
                        $('#red-letter-control').prop('checked', false);
                    } else if ($('#red-letter-control').is(':not(:checked)'))
                    {
                        $('#red-letter-control').prop('checked', true);
                    }
                    if ($('#red-letter-control').prop('checked') === true)
                    {
                        Cookies.set('bibleRedLetter', 'true');
                        $('chapter .red').removeClass('red-off');
                    } else if ($('#red-letter-control').prop('checked') === false)
                    {
                        Cookies.set('bibleRedLetter', 'false');
                        $('chapter .red').addClass('red-off');
                    }
                });

                $('#bible-text-small').click(function (e)
                {
                    e.preventDefault();
                    changeBibleTextSize('small', '16px', '22px');
                });

                $('#bible-text-medium').click(function (e)
                {
                    e.preventDefault();
                    changeBibleTextSize('medium', '18px', '26px');
                });

                $('#bible-text-large').click(function (e)
                {
                    e.preventDefault();
                    changeBibleTextSize('large', '22px', '30px');
                });
            }
        },
        'single_wpsl_stores': {
            init: function ()
            {
                // Register Your Church AJAX submission
                $(document).ready(function ($)
                {
                    $('#edit_church_form').on('submit', function (e)
                    {
                        e.preventDefault();
                        // Get post content from form values
                        var churchId = $('#post_id').val();
                        var churchName = $('#title').val();
                        var churchDenomination = $('#wpsl_denomination').val();
                        var churchStreet = $('#wpsl_address').val();
                        var churchCity = $('#wpsl_city').val();
                        var churchState = $('#wpsl_state').val();
                        var churchZip = $('#wpsl_zip').val();
                        var churchCountry = $('#wpsl_country').val();
                        var churchPhone = $('#wpsl_phone').val();
                        var churchWebsite = $('#wpsl_url').val();
                        var churchEmail = $('#wpsl_email').val();
                        var churchContact = $('#wpsl_contact_name').val();
                        var churchServiceTimes = $('#wpsl_service_times').val();
                        var churchPastor = $('#wpsl_pastor').val();
                        var churchOfficeHours = $('#wpsl_office_hours').val();
                        var churchSpecializedMinistry = $('#wpsl_specialized_ministry').val();
                        // var churchChildrensMinistry = $("input[name='church_childrens_ministry']").is(":checked");

                        function churchAddress(street, city, state)
                        {
                            street = street.split(' ').join('+');
                            city = city.split(' ').join('+');
                            return street + ',+' + city + ',+' + state;
                        }

                        var address = churchAddress(churchStreet, churchCity, churchState);
                        $.ajax({
                            method: "GET",
                            url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + APP_DATA.googlemaps_api_key,
                            success: function (response)
                            {
                                var churchLat = response.results[0].geometry.location.lat;
                                var churchLng = response.results[0].geometry.location.lng;

                                // Gather Church post data
                                var churchEditData = {
                                    // type: "wpsl_stores",
                                    // status: "pending",
                                    title: churchName,
                                    wpsl_denomination: churchDenomination,
                                    wpsl_address: churchStreet,
                                    wpsl_city: churchCity,
                                    wpsl_state: churchState,
                                    wpsl_country: churchCountry,
                                    wpsl_zip: churchZip,
                                    wpsl_lat: churchLat,
                                    wpsl_lng: churchLng,
                                    wpsl_phone: churchPhone,
                                    wpsl_url: churchWebsite,
                                    wpsl_email: churchEmail,
                                    wpsl_contact_name: churchContact,
                                    wpsl_service_times: churchServiceTimes,
                                    wpsl_pastor: churchPastor,
                                    wpsl_office_hours: churchOfficeHours,
                                    wpsl_specialized_ministries: churchSpecializedMinistry,
                                    // wpsl_children: churchChildrensMinistry,
                                };

                                $.ajax({
                                    method: "POST",
                                    url: APP_DATA.rest_root + 'wp/v2/churches/' + churchId,
                                    data: churchEditData,
                                    beforeSend: function (xhr)
                                    {
                                        xhr.setRequestHeader('X-WP-Nonce', APP_DATA.nonce);
                                    },
                                    success: function (response)
                                    {
                                        var responseSuccessTitle = STRING.church_edit_success_title;
                                        var responseSuccessMessage = STRING.church_edit_success_message;

                                        // Launch modal dialog to display success message
                                        BootstrapDialog.show({
                                            title: responseSuccessTitle,
                                            message: responseSuccessMessage,
                                            animate: true,
                                            closable: true,
                                            closeByBackdrop: true,
                                            closeByKeyboard: true,
                                            buttons: [{
                                                label: STRING.modal_button_my_profile,
                                                cssClass: 'btn-md btn-default',
                                                action: function (dialogRef)
                                                {
                                                    window.location = APP_DATA.profile_url;
                                                },
                                            },
                                            {
                                                label: STRING.modal_button_dismiss,
                                                cssClass: 'btn-md btn-default',
                                                action: function (dialogRef)
                                                {
                                                    window.location.reload();
                                                }
                                            }]
                                        });
                                    },
                                    error: function (response)
                                    {
                                        var responseFailureTitle = STRING.church_edit_failure_title;
                                        var responseFailureMessage = STRING.church_edit_failure_message;
                                        // Launch modal dialog to display error message
                                        BootstrapDialog.show({
                                            title: responseFailureTitle,
                                            message: responseFailureMessage,
                                            animate: true,
                                            closable: true,
                                            closeByBackdrop: true,
                                            closeByKeyboard: true,
                                            buttons: [{
                                                label: STRING.modal_button_retry,
                                                cssClass: 'btn-md btn-default',
                                                action: function (dialogRef)
                                                {
                                                    dialogRef.close();
                                                }
                                            }]
                                        });
                                    }
                                });
                            }
                        });
                    });
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args)
        {
            var fire;
            var namespace = NewLife;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire)
            {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function ()
        {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm)
            {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
